import React, { Component } from "react";
import { Route, Switch,Router} from "react-router";
import "./fabric";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "./actions/userActions";
import * as familyspaceActions from "./actions/familySpaceActions";
import { Unknown } from "./pages/unknown/Unknown";
import { Catalog } from "./pages/catalog/Catalog";
import { Fcatalog } from "./pages/family/Fcatalog";
import { Fgranite } from "./pages/family/Fgranite";
import { Faccessory } from './pages/family/Faccessory';
import { Fpattern } from "./pages/family/Fpattern";
import { Fengraving } from "./pages/family/Fengraving";
import { Ffinalization } from "./pages/family/Ffinalization";
import { Monument } from "./pages/monument/Monument";
import { Configurator } from "./pages/configurator/Configurator";
import { AdminFamilyList } from "./pages/admin_family/AdminFamilyList";
import { AdminDashboard } from "./pages/admin_family/AdminDashboard";
import { AdminCatalog } from "./pages/admin_family/AdminCatalog";
import { manageStats }  from "./services/stats.service";
import { getDesignTypeConfiguration, isFamilySpaceOn } from './services/utils.service';
import history from "./history";
import { Fcustomize } from "./pages/family/Fcustomize";
import { FamilySpace } from "./pages/family_space/FamilySpace";
import socketIOClient from 'socket.io-client';
import {loadFonts} from "./services/generateEngravings.service";
import FLoader from './components/FLoader';
import { gtmFamilyInit } from './services/gtmFamily';
import { gtmPro } from './services/gtmPro';
import * as apiService from "./services/api.service";
import * as adminConfigActions from "./actions/adminConfigActions";
import Loader from "./components/Loader";

/**
 * Simple élément root de notre application. Pour le moment, il ne fait qu'afficher l'élément fils
 * fourni par le router
 */
class AppComponent extends Component {
  state={
    user_loaded: false,
    fonts_loaded: false,
    designType: null,
    fs_token:null,
  };

  checkOddFamilyEntry () {
    //Temporary fix for old family URLs
    if (this.props.history.location && this.props.history.location.search) {
      const endUrl = this.props.history.location.search;
      if (endUrl.startsWith("?items=") && endUrl.includes("famille")){
          //URL famille du type /?items=CA100782_famille&...
          let clientId = endUrl.split("?items=")[1].split("_famille")[0];
          if (clientId)
          { history.push(`/famille/${clientId}`);}
      }
    }
  }

  async componentDidMount() {
    if (this.isFamilyConfigurator()) {
      this.setTitleAndDescription();
      this.setMetaTags()
      this.startSocket();
      const { pathname } = this.props.history.location
      if(pathname) {
        const clientId = pathname.split("/")[2];
        if(clientId) {
          gtmFamilyInit(clientId);
        }
      }
    }
    else {
      gtmPro();
    }

    this.checkOddFamilyEntry();
    const designType = await getDesignTypeConfiguration();
    if (!this.isFamilyConfigurator()) {
      await this.props.userActions.getParameters();
    }
    await this.props.userActions.load();
    await this.getFamilyConfig();
    this.setState({user_loaded: true, designType});
    //this.getMetaDesc();
  }
   componentDidUpdate(){
    document.getElementById('dynamic-desc').setAttribute("content",this.props.user.metaDescription);
    if (this.isFamilyConfigurator()){
      this.setTitleAndDescription();
      this.setMetaTags()
      if (!this.state.fs_token) {
        this.startSocket()
      }
    }
    if (this.state.user_loaded && !this.state.fonts_loaded && this.props.user && this.props.user.id){
      loadFonts(this.props.user);
      this.setState({fonts_loaded:true})
    }
  }
  async startSocket(){
    const socketURL = process.env.REACT_APP_WS;
    if (this.props.storedToken && !this.props.socket){
      // init socket for the familyspace chat
      const socket = socketIOClient(socketURL, {query: {token:this.props.storedToken}, transports: ['polling']});

      socket.on('connect', async () => {
        await this.props.familyspaceAction.setSocket(socket);
        this.setState({fs_token:true})
      });
    }
  }

  async getFamilyConfig () {
    const {family} = this.props.user;
    const familyConfig = await apiService.get(`/api/familyconfigdef/${family}`);
    // initialiser les couleurs par défaut
    if(familyConfig && familyConfig.config) {
      let {mainColor, fontColor} = familyConfig.config
      if (mainColor === "" || !mainColor) { familyConfig.config.mainColor = "#FF9D1E" }
      if (fontColor === "" || !fontColor) { familyConfig.config.fontColor = "#001F2A" }
    }
    this.props.adminConfigActions.initConfig(familyConfig);
  }

  isFamilyConfigurator(){
    const url = window.location.href;
    return url.includes("famille")
  }

  setTitleAndDescription() {
    document.title = "Configurateur famille"
    document.querySelector('meta[name="description"]').setAttribute("content", "Configurez votre monument funéraire unique et personnalisez-le. Profitez d'un large catalogue de monuments funéraires et cinéraires modernes et de qualité.")
  }

  setMetaTags() {
    const metaTag = document.createElement('meta')
    metaTag.name = 'googlebot'
    metaTag.content = 'noindex, nofollow'

    // Add the meta tag to the document head
    document.head.appendChild(metaTag)
  }

  render() {
    const { history,user } = this.props;
    const {user_loaded,designType, fonts_loaded} = this.state;
    const className = this.isFamilyConfigurator() ? "Family" : "height-98vh";
    
    return (
      <div className={className}>
        {user_loaded && designType && fonts_loaded ?
        <Router history={history}>
          <Switch>
            {!user.family && <Route exact path="/" component={Catalog} />}
            <Route path="/monument/:monument" component={Monument} />
            <Route path="/selection/:selectionId" component={Monument} />
            <Route path="/configuration/:configId" component={Configurator} />
            <Route exact path="/quote_variant/:configId" component={Configurator} />
            <Route exact path ="/unknown" component={Unknown}/>
            <Route exact path="/adminfamily/list" component={AdminFamilyList} />
            <Route exact path="/adminfamily/dashboard" component={AdminDashboard} />
            <Route exact path="/admin/catalog" component={AdminCatalog} />
            {user.family &&
              <Switch>
                <Route exact path="/" component={Fcatalog} />
                <Route exact path="/famille/:clientId" component={Fcatalog} />
                {/* <Route exact path="/famille/:clientId/:configId" component= {props=>designType ==="B" ? <Fcustomize {...props} /> :<Fgranite {...props}/> }/> */}
                <Route exact path="/famille/:clientId/choisir/:monumentId" component={props => <Fcatalog {...props} step={1} />} />
                <Route exact path="/famille/:clientId/config/:monumentId" component={props => <Fcatalog {...props} step={2} />} />
                <Route exact path="/famille/:clientId/granit/:monumentId" component={props => <Fcatalog {...props} step={2} />} />
                <Route exact path="/famille/:clientId/selection/:selectionId" component={props => <Fcatalog {...props} step={6} />} />
                <Route exact path="/famille/:clientId/finalisation/:monumentId" component={props => <Fcatalog {...props} step={6} />} />
                <Route exact path="/famille/:clientId/:configId/customize" component={props => <Fcustomize {...props} />} />
                <Route exact path="/famille/:clientId/:configId/fgranit" component={props=>{manageStats(props,"fgranit");return (<Fgranite {...props}/>)}} />
                <Route exact path="/famille/:clientId/:configId/fgravure" component={props=>{manageStats(props,"fgravure");return (<Fengraving {...props} designType={designType} />)}} />
                <Route exact path="/famille/:clientId/:configId/fmotif" component={props=>{manageStats(props,"fmotif");return (<Fpattern {...props} designType={designType} />)}}  />
                <Route exact path="/famille/:clientId/:configId/faccessoire" component={props=>{manageStats(props,"faccessoire");return (<Faccessory {...props} designType={designType} />)}}  />
                <Route exact path="/famille/:clientId/:configId/ffinalisation" component={props=>{manageStats(props,"ffinalisation");return (<Ffinalization {...props}/>)}} />
                {isFamilySpaceOn(user) &&  <Route  path="/famille/:clientId/familyspace" component={props => <FamilySpace {...props} />} />
              }
              </Switch>
            }
            {/* <Redirect path="*" to="/unknown" /> */}
          </Switch>
        </Router>
        : (this.isFamilyConfigurator() ? <FLoader /> : <Loader />)
        }
      </div>
    );
  }
}

export const App = connect(
  state => ({
    user: state.user,
    storedToken: state.familyService.token,
    socket: state.familyService.socket
  }),
  dispatch => ({
    userActions: bindActionCreators(userActions, dispatch),
    familyspaceAction: bindActionCreators(familyspaceActions, dispatch),
    adminConfigActions: bindActionCreators(adminConfigActions, dispatch),
  })
)(AppComponent);

export default App;
