const FRAME_DEFAULT_BEDDING = 5;
const FRAME_MINIMAL_SIZE = 7;

const getSizehalved = (frameSize, monumentSize) => {
  if (!frameSize || !monumentSize || monumentSize > frameSize) {
    return 0
  } else {
    return ((frameSize - monumentSize) / 2)
  }
}

export const frameDimensions = (frame, monumentLayoutSize) => {
  const {layout,width,depth,frontLayout} = frame;
  //Cas particulier de la recette W en standard non centré donc les tailles ne sont pas bonnes ne pas les afficher
  if (frame.mode !== "custom" && frame.reference && frame.reference[0] === "W") { return null }
  let dimensions = { front: {}, left: {}, back: {}, right: {} };
  const bedding = getBedding(frame, monumentLayoutSize);
  const frontDepth = getFrontDepth(frame, monumentLayoutSize);
  const leftWidth = getLeftWidth(frame, monumentLayoutSize);
  const leftRightWidth = getLeftRightWidth(frame, frontDepth, bedding, monumentLayoutSize);
  ["back", "left", "right", "front"].forEach(side => dimensions[side] = { width: 0, depth: 0 });
  dimensions.front.width = frontLayout === "parpaing" ? width : monumentLayoutSize.width - 2*bedding; // Largeur avant = largeur semelle car toujours parpaing avant
  dimensions.front.depth = frontDepth + bedding; // Profondeur avant = Vue avant + lit de pose
  dimensions.back.width = layout === "parpaing" ? width : monumentLayoutSize.width - 2 * bedding; // Largeur arrière = semelle si parpaing sinon monument - 2*lit de pose 
  dimensions.back.depth = depth - monumentLayoutSize.depth - frontDepth + bedding; // Profondeur arrière = semelle - monument - vue avant + lit de pose  
  dimensions.left.width = leftRightWidth // C'est la moitié de la différence semelle/monument + le lit de pose
  // dimensions.left.depth = layout === "parpaing" ? depth - dimensions.front.depth - dimensions.back.depth : depth - dimensions.front.depth;
  dimensions.left.depth = leftWidth + bedding;
  // Pour profondeur gauche et droite si parpaing on enlève les profondeurs avant et arrière sinon seulement l'avant
  dimensions.right.width = leftRightWidth
  dimensions.right.depth = width - monumentLayoutSize.width - leftWidth + bedding;

  return dimensions;
}

const getFrameWidth = (frame, layoutWidth) => {
  if (frame.width < layoutWidth) {
    return layoutWidth
  }

  return frame.width
}

const getFrameDepth = (frame, layoutDepth) => {
  if (frame.depth < layoutDepth) {
    return layoutDepth
  }

  return frame.depth
}
const getDefaultFrameView = (params) => {
  const defaultView = params.find(p => p.key === 'CONFIG3D_DEFAULT_FRAME_VIEW');
  return defaultView && parseInt(defaultView.value) !== NaN ? defaultView.value : 15;
}

export const defaultFrame = (config, params) => {
  const frame = config.configuration.frame
  const kindGroup = _.find(config.options.frames, {kind: frame.kind})
  const selectedLayout = _.find(config.options.layouts, {reference: config.configuration.monument.layout});

  frame.width = kindGroup.defaults && kindGroup.defaults.length>0 ? kindGroup.defaults[0].width : getFrameWidth(frame, selectedLayout.size.width)
  frame.depth = kindGroup.defaults && kindGroup.defaults.length>0? kindGroup.defaults[0].depth : getFrameDepth(frame, selectedLayout.size.depth)
  frame.leftWidth = getSizehalved(frame.width, selectedLayout.size.width)
  frame.frontDepth = getSizehalved(frame.depth, selectedLayout.size.depth)

  let newValue = {
    mode: 'standard',
    layout:"parpaing",
    ..._.omit(kindGroup.defaults[0], ['name']),
  }
  if(kindGroup.defaults.length<=0){
    const defaultView = getDefaultFrameView(params)
    frame.width = selectedLayout.size.width + defaultView * 2;
    frame.depth = selectedLayout.size.depth + defaultView * 2;
    frame.leftWidth = getSizehalved(frame.width, selectedLayout.size.width)
    frame.frontDepth = getSizehalved(frame.depth, selectedLayout.size.depth)
    newValue = {
      mode: 'custom',
      layout:"parpaing",
      reference: 'SEM',
     details: frame.width + " x " + frame.depth + " x " + frame.heights.join("/")
    }
  }    
    
  let newFrame = Object.assign({}, frame, newValue);
  return newFrame
}

const getLeftRightWidth = (frame, frontDepth, bedding, monumentLayoutSize) => {
  const {layout,width,depth,frontLayout} = frame;
  if (layout === "parpaing") {
    if (frontLayout === "parpaing") {
      return depth - (frontDepth + depth - monumentLayoutSize.depth - frontDepth + 2*bedding)
    }
    if (frontLayout === "closoir") {
      return depth - (depth - monumentLayoutSize.depth - frontDepth + bedding)
    }
  } else {
    if (frontLayout === "parpaing") {
      return depth - ( frontDepth + bedding)
    }
    if (frontLayout === "closoir") {
      return depth
    }
  }
}

export const getFrontDepth = (frame, monumentLayoutSize) => {
  const { depth, initDepth, frontDepth } = frame;

  if (!initDepth) {
    return getSizehalved(depth, monumentLayoutSize.depth);
  }
  return frontDepth;
}

export const getLeftWidth = (frame, monumentLayoutSize) => {
  const { width, initDepth, leftWidth } = frame;

  if (leftWidth === 0 || isNaN(leftWidth)) {
    return getSizehalved(width, monumentLayoutSize.width);
  }
  return leftWidth;
}

const getBedding = (frame, monumentLayoutSize) => {
  const { width, depth, initDepth, frontDepth, bedding } = frame;
  if (!bedding || bedding < FRAME_MINIMAL_SIZE) { // On ne modifie que si non renseigné ou infériur à la taille minimale
    if (initDepth && (frontDepth === 0 || ((depth - monumentLayoutSize.depth - frontDepth) === 0))) {
      return FRAME_MINIMAL_SIZE  // Si vue avant (ou arrière) mise à zéro on retourne la taille minimale
    }
    if ((monumentLayoutSize.depth === depth) || (monumentLayoutSize.width === width)) {
      return FRAME_MINIMAL_SIZE   // Si la largeur ou la profondeur de la recette est égale à celle de la semelle on retourne la taille minimale
    }
  }
  if (!bedding) {
    return FRAME_DEFAULT_BEDDING
  }

  return bedding
}
