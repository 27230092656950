import React, { useEffect, useState, useRef, useContext } from 'react';
import { connect } from 'react-redux';
import { updateFrameAndVeneerScenes } from '../../../actions/updateConfigurationActions';
import { getCameraParams, getImageUrl, getSnapshot } from '../../../services/lumiscaphe.service';
import { TranslationContext } from '../../../context/TranslationContext';
import * as _ from "lodash";
import { getVeneerDefaultData } from '../../../services/utils.service';
import Loader from '../../../components/Loader';

const masterscene = {
  "isMaster": true,
  "configuration": "",
  "visible": true,
}

const renderParametersNormal = {
  "screenSpaceReflections": true,
  "screenSpaceReflectionsGrazingAngleFadeoff": 0.03,
  "width": 1920,
  "height": 1080,
  "antialiasing": false,
  "superSampling": "2",
  "postProcess": true
}

const renderParametersUp = {
  "screenSpaceReflections": false,
  "screenSpaceReflectionsGrazingAngleFadeoff": 0.03,
  "width": 1800,
  "height": 1800,
  "antialiasing": false,
  "superSampling": "2",
  "postProcess": true
}

const defaultelements = {
  encoder: {
    jpeg: {
      quality: 85
    }
  }
}

const LumiImagePreview = ({ type, showSelect, configuration, layouts, frameData, veneerData, containerStyle, imageStyle, selectStyle, cameraView, onCameraViewChange, veneerDefaultData }) => {
  const t = useContext(TranslationContext);

  const [imageUrl, setImageUrl] = useState(null);
  const [snapshotObj, setSnapshotObj] = useState(null);
  const [cameraPov, setCameraPov] = useState(null);
  const [noVisual, setNoVisual] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const mounted = useRef(true);

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    setIsLoading(true); // Show loader before starting the process

    const setupSnapshot = async () => {
      const newConfiguration = _.cloneDeep(configuration);
      if (type === "frame") {
        newConfiguration.veneer.layout = "";
        newConfiguration.frame = frameData;
      } else if (type === "veneer") {
        newConfiguration.frame.width = 0;
        newConfiguration.veneer = veneerData;
      }
      const veneerDefault = getVeneerDefaultData(veneerDefaultData);
      const objScenes = updateFrameAndVeneerScenes(newConfiguration, layouts, true, veneerDefault);
      if (!objScenes || objScenes.length === 0) {
        setImageUrl(null);
        setNoVisual(true);
        setIsLoading(false); // Hide loader if no scenes are available
        return;
      }
      objScenes.unshift(masterscene);
      if (cameraView === "up") {
        masterscene.database = "b6ebe309-156c-4cc2-85ed-564a4d942521";
      } else {
        masterscene.database = "4ef9082a-8c8c-4ac9-9d17-97f252921a6d";
      }

      const initialSnapshotObj = {
        scene: objScenes,
      };

      try {
        const CameraPov = await getCameraParams(initialSnapshotObj);
        if (mounted.current) {
          setCameraPov(CameraPov);
          setNoVisual(false);
          setSnapshotObj(initialSnapshotObj);
        }
      } catch (error) {
        console.error('Error in initial setup:', error);
        setIsLoading(false);
        setNoVisual(true);
      }
    };

    setupSnapshot();
  }, [frameData, veneerData]);

  useEffect(() => {
    const updateSnapshot = async () => {
      if (mounted.current) {
        setImageUrl(null);
        setIsLoading(true); // Show loader before starting the snapshot request
      }
      if (!snapshotObj || !cameraPov) {
        setIsLoading(false); // Hide loader if prerequisites are not met
        return;
      }

      const updatedSnapshotObj = { ...snapshotObj };

      updatedSnapshotObj.mode = {
        image: {
          camera: cameraView === "up" ? {
            "lens": {
              "view": "isometric",
              "fov": 45,
              "axis": "y"
            },
            pov: cameraPov.up,
            "sensor": {
              "id": "df60d800-0230-4cda-b146-a40886351698",
            },
          } : {
            "lens": {
              "fov": 18.887
            },
            pov: cameraPov.normal,
            "sensor": {
              "id": "4ddb179e-970b-4714-975d-9965517137d9"
            },
          }
        }
      };

      const finalSnapshotObj = {
        ...updatedSnapshotObj,
        renderParameters: cameraView === "up" ? renderParametersUp : renderParametersNormal,
        ...defaultelements
      };

      try {
        const snapshotData = await getSnapshot(finalSnapshotObj);
        if (mounted.current && snapshotData && snapshotData.url) {
          setImageUrl(getImageUrl(snapshotData.url));
        }
      } catch (error) {
        console.error('Error updating snapshot:', error);
        setIsLoading(false);
        setNoVisual(true);
      }
    };

    updateSnapshot();
  }, [cameraView, snapshotObj]);

  return (
    <div style={containerStyle} className="LumiImagePreview">
      {showSelect === true &&
        <select value={cameraView} onChange={(e) => onCameraViewChange(e.target.value)} style={selectStyle}>
          <option value="normal">{t("config3d_monument_modal_lumiscaphe_vue_normal")}</option>
          <option value="up">{t("config3d_monument_modal_lumiscaphe_vue_up")}</option>
        </select>
      }

      {isLoading && <Loader />}
      {imageUrl &&
        <img
          src={imageUrl}
          alt={`${type} preview`}
          style={imageStyle}
          className={`${isLoading && "hidePreview"}`}
          onLoad={() => setIsLoading(false)} 
          onError={() => {
            setIsLoading(false);
            setNoVisual(true);
            setImageUrl(null);
          }}
        />
      }

      {noVisual && !isLoading && <p className="NoVisualMessage">{t("config3d_monument_modal_lumiscaphe_no_visual")}</p>}

    </div>
  );
};

const mapStateToProps = (state) => ({
  configuration: state.configurator.current.configuration,
  layouts: state.configurator.current.options.layouts,
  veneerDefaultData: state.configurator.current.options.defaultVeneer,
});

export default connect(mapStateToProps)(LumiImagePreview);