import {isQuote} from './restrictedConfiguration';
import {getReactAppExtranet} from  './utils.service';
import {checkConfigHideFlowerImg} from './flower.service';
import {imgs} from './images.service';

const getTypeConfig = (config) =>{
  const type = isQuote(config) ? 'quote_variants':'pro-space/configurations';
  return type
  }

// Affichage de l'image de la configuration notamment utilisée dans la vue principale du configurateur
export const displayStandardConfigImage = (config,size="large",forceHideFlowers=false)=> {
 
  const typeConfig = getTypeConfig(config);
  const flowers = forceHideFlowers ? 0: checkConfigHideFlowerImg() ? 0:1;
  const category = config.monument.category || ""
  let granite = config.monument.graniteMain
  if (category === "SEM") {
    granite = config.frame.granite
  } else if(category === "PLT") {
      granite = config.veneer.granite
  }
  return getReactAppExtranet(`/${typeConfig}/${config.reference}/picture/${size}?granite=${granite}&graniteSecondary=${config.monument.graniteSecondary}&flowers=${flowers}&v=${Date.now()}`) ;
}
  
 // Affichage dans un granit donnée de la configuration notamment utilisée en miniature sans décoration dans les choix de granit
export const displayGranitConfigImage = (config,granite,tag=false,granitesecondary=null,size="thumbnail",forceHideFlowers=false,accessories=0,decorations=0,framegranite=0, veneergranite=0, withDefaultPattern=0, family=0, headstoneRef=0)=> {
  const typeConfig = getTypeConfig(config);
  const tagInfo = tag ? `&tag=${tag}` : '';
  const flowers = forceHideFlowers ? 0: checkConfigHideFlowerImg() ? 0:1;
  const graniteSecondary = granitesecondary || config.monument.graniteSecondary
  const frameKind = config.frame && config.frame.kind? config.frame.kind : ""
  const veneerLayout = config.veneer && config.veneer.layout? config.veneer.layout : ""
  const category = config.monument.category || ""

  if (headstoneRef) {
    withDefaultPattern = 1
  } else {
    withDefaultPattern = 0
  }
  const endUrl = `configgranit=1&category=${category}&framekind=${frameKind}&veneerlayout=${veneerLayout}&granite=${granite}&graniteSecondary=${graniteSecondary}&accessories=${accessories}&decorations=0&flowers=${flowers}&framegranite=${framegranite}&veneergranite=${veneergranite}&withDefaultPattern=${withDefaultPattern}&headstoneRef=${headstoneRef}&v=${Date.now()}`;
  return getReactAppExtranet(`/${typeConfig}/${config.reference}/picture/${size}?${endUrl}${tagInfo}`) ;
}

// Affichage du monument vue de haut notamment utilisé dans le placement des accessoires
export const displayMonumentTopConfigImage = (config,size="large",drawing=true)=>{
  const typeConfig = isQuote(config) ? 'quote_variants':'products';
  const ref  = isQuote(config) ? config.reference : config.monument.productId+config.monument.graniteMain; 
  const frame = config.frame && config.frame.mode !== "" ? getFrame(config.monument, config.frame) : "";
  const dw = drawing ? 1:0;
  const updatedAtTimestamp = config.updatedAt ? new Date(config.updatedAt).getTime() : Date.now(); 
  const config_ref = config.reference && !isQuote(config) ? config.reference : "";
  return getReactAppExtranet(`/${typeConfig}/${ref}/top/${size}.jpg?flowers=0&config_ref=${config_ref}&kind=${config.monument.kind}&drawing=${dw}${frame}&time=${updatedAtTimestamp}`) ;
}

// Affichage de l'accessoire seul (soit en vue normale ex: finalisation soit en vue de haut ex: dans le placement)
export const displayAcccessoryConfigImage = (config,accessoryInfo,granite=null,top= false,forceHideFlowers=true,size="thumbnail")=>{
  //L'url d'affichage de l'image de l'accessoire doit commencer par 'quote_variants' s'il s'agit d'une variante ou 'products' s'il s'agit d'un produit ou configuration:
  let beginUrl = isQuote(config) ? '/quote_variants/'+config.reference+'/accessories/' : '/products/'
  const flowers = forceHideFlowers ? 0: checkConfigHideFlowerImg() ? 0:1;
  const accGranite = accessoryInfo.granite ? accessoryInfo.granite : config.monument.graniteMain;
  const accessoryBaseId = isQuote(config) ? accessoryInfo.id : null;
  const config_ref = config.reference && !isQuote(config) ? config.reference : "";
  return displaySimpleAccesory(accessoryInfo.reference,granite || accGranite,size,top,0,beginUrl,accessoryBaseId) + "&config_ref="+config_ref;
}

export const displaySimpleAccesory = (reference,granite,size="thumbnail",top=false,flowers=0,beginUrl='/products/',accessoryBaseId) => {
  //Dans le cas d'un produit on utilise comme référence de l'accéssoire la reference sinon pour les quote_variants on utilise databaseId (id):
  let accessoryRef=  accessoryBaseId ? accessoryBaseId : reference + granite;
  //Pour l'instant ça ne marche que pour les accessoires standards;
  const topPart = top ? "top/":"picture/";
  const url = `${beginUrl+accessoryRef}/${topPart}${size}.png?flowers=${flowers}&granite=${granite}`
  return getReactAppExtranet(url);
}

export const displayFaceConfigImage = (config,face,drawing=false,size='large')=> {
  drawing = drawing ? 1:0;
  const pieceRef = face.piece.reference;
  const profileRef = face.profile.reference;
  const typeConfig = isQuote(config) ? 'quote_variants':'products';
  let ref = ""
  let granite = config.monument.graniteMain
  let index = ""
  if(face.piece.name =="Accessoire" && !isQuote(config))
  {
    ref= pieceRef; 
    size="small";
    granite = face.piece.granitCode+"&cache=0"
  } else if (face.piece.name =="Accessoire" && isQuote(config)) {
    ref  = config.reference; 
    granite = face.piece.granitCode+"&cache=0"
    index = face.piece.index ? face.piece.index : ""
  } else {
    ref  = isQuote(config) ? config.reference : config.monument.productId+config.monument.graniteMain; 
  }
  const graniteSecondary = config.monument.graniteSecondary
  
  const url =  getReactAppExtranet(`/${typeConfig}/${ref}/face/${pieceRef}/${profileRef}/${size}.jpg?drawing=${drawing}&granite=${granite}&graniteSecondary=${graniteSecondary}&index=${index}`) ;
  return url;

}

export const getMonumentImage = (monument,size,drawing=0) =>{
  if (monument.image){return  monument.image}
  const flowers = checkConfigHideFlowerImg() ? 0:1;
  const granite = monument.graniteMain ? monument.graniteMain.reference:"";
  const graniteSecondary = monument.graniteSecondary ? monument.graniteSecondary.reference:"";
  const frame = getFrame(monument);//pas de semelle pour les crémations
  return getReactAppExtranet(`/products/${monument.productId}${granite}/picture/${size}.jpg?drawing=${drawing}&flowers=${flowers}${frame}&graniteSecondary=${graniteSecondary}`); 
}
export const getMonumentImageByRef = (monument,size,category,drawing=0,hideFlowers=0,granites= null,withDefaultPattern=1) =>{
  const flowers = hideFlowers ? 0 : 1;
  const granite = granites ? granites.split('-')[0] : "";
  // const graniteSecondary = monument.graniteSecondary ? monument.graniteSecondary.reference:"";
  const frame = getFrameByCatego(category)
  const graniteSecondary = (granites && granites.split('-').length >= 2 && granites.split('-')[1] != "" ) ? granites.split('-')[1] : ""
  return getReactAppExtranet(`/products/${monument}${granite}/picture/${size}.jpg?drawing=${drawing}&flowers=${flowers}${frame}&graniteSecondary=${graniteSecondary}&withDefaultPattern=${withDefaultPattern}&config3d_reference=${monument}&granite=${granite}`); 
}

export const createMonumentImage = (monument, granite,size,drawing=0) =>{
  const flowers = checkConfigHideFlowerImg() ? 0:1;
  const frame = getFrame(monument);//pas de semelle pour les crémations
  return getReactAppExtranet(`/products/${monument.productId}${granite}/picture/${size}.jpg?drawing=${drawing}&flowers=${flowers}${frame}`); 
}

export const getMonumentImageByGranit = (monuments, granite, size, drawing=0, flowers=1) =>{
  
  return imgs.configuration.iso.large_granite(granite).then(data=> {
    const images = {}
    monuments.forEach(monument=>{
      const frame = getFrame(monument); //pas de semelle pour les crémations
      let img = (granite=='0'? '': getReactAppExtranet(`/products/${monument.productId}${granite}/picture/${size}.jpg?drawing=${drawing}&flowers=${flowers}${frame}`));
      const cache_image = data.find(element => element[monument.preconfId] );
      if(cache_image && cache_image[monument.preconfId]){
        img = cache_image[monument.preconfId]
      }
      if (img) { images[monument.reference] = img }
    })
    return images;
  })
}

export const getMonumentImageFrame = (configAndOptions,size,drawing=0) =>{
  const monument = configAndOptions.configuration.monument;
  if (monument.image){return  monument.image}
  const typeConfig = getTypeConfig(configAndOptions);
  const flowers = checkConfigHideFlowerImg() ? 0:1;
  const granite = monument.graniteMain ? monument.graniteMain : "";
  const frame = configAndOptions.configuration.frame.kind != "" ? `&frame=${configAndOptions.configuration.frame.kind}` : getFrame(monument);
  return getReactAppExtranet(`/${typeConfig}/${configAndOptions.configuration.reference}/picture/${size}?granite=${monument.graniteMain}&flowers=${flowers}&v=${Date.now()}`) ;
 // return getReactAppExtranet(`/${typeConfig}/${monument.productId}${granite}/picture/${size}.jpg?drawing=${drawing}&flowers=${flowers}${frame}`); 
}

export const displayQuote3D = (configuration,size='large')=>{
  const typeConfig = isQuote(configuration) ? 'quote_variants':'pro-space/configurations';
  return getReactAppExtranet(`/${typeConfig}/${configuration.reference}/view360/${size}.html`);
}

const getFrame = (monument, frame = null) => {
  if (monument.category && monument.category !== "ACC") {
    if (frame?.kind) {
      return `&frame=${frame.kind}`;
    }
    return "&frame=C";
  }
  return "";
};
const getFrameByCatego = (category)=> category && category !== "ACC" && !(['900-999', 'CIN'].indexOf(category) >= 0) ? "&frame=C": "";


