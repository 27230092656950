import React from 'react';
import { ReactComponent as LoadingPicto } from "../images/icons-config-famille/loading.svg";

function Loader() {
  return (
    <div className="loadingContainer">
      <LoadingPicto className="loadingImg" alt="Chargement..." />
    </div>
  );
}

export default Loader;