import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {ModalButton} from '../../../components/ModalButton';
import {checkDisplaySimpleConfig} from "../../../services/utils.service";
import {displayQuote3D} from '../../../services/image.fct.service';
import {isQuote} from '../../../services/restrictedConfiguration';
import { TranslationContext } from "../../../context/TranslationContext";

class View3DComponent extends Component {
  static contextType = TranslationContext;
  static propTypes = {
    configuration: PropTypes.object.isRequired,
  };

  modal;

  render() {
    const t = this.context;
    const additionalInfo = t("config3d_actionbuttons_360view") || "Vue 3D";
     return (
      <div className="View3D btn-wrapper">
       {!checkDisplaySimpleConfig(this.props.configuration) && 
          <ModalButton ref={(_modal) => this.modal = _modal} buttonContent="Vue 3D" buttonIcon="view3d-icon" buttonClassName="btn-config-tertiary secondary2 text-light secondary2-hover secondary2-hover-border" additionalInfo={additionalInfo}>
            <iframe src={displayQuote3D(this.props.configuration)} width="100%" height="100%" style={{border: 0, margin: 0, padding: 0, borderRadius: "0.938rem"}}/>
          </ModalButton>}
      </div>
    );
  }
}

export const View3D = connect((state, ownProps) => ({
    configuration: state.configurator.current.configuration,
  })
)(View3DComponent);
