import React, { Component} from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as updateConfigurationActions from "../../../../actions/updateConfigurationActions";
import * as _ from "lodash";
import {getOrganization} from '../../../../services/userRights.service';
import { TranslationContext } from "../../../../context/TranslationContext";
import {InputNumber} from '../../../../components/InputNumber';
import validButton from '../../../../images/base-step.svg';
import succcessIcon from '../../../../images/success-icon.svg';
import LumiImagePreview from '../LumiImagePreview';
import { isSmartphone } from '../../../../services/domFunctions';
import {getVeneerHeight, getVeneerThickness, getVeneerDimensions, getVeneerOverall, veneerAssemblyType} from '../../../../services/lumiscaphe.service'
import { getVeneerDefaultData } from "../../../../services/utils.service";

class VeneerConfigureComponent extends Component {
  static contextType = TranslationContext;
  static propTypes = {
    veneer: PropTypes.shape({
      dimensions: PropTypes.shape({
        back: PropTypes.shape({left: PropTypes.number,right: PropTypes.number,length: PropTypes.number,thickness: PropTypes.number}),
        front: PropTypes.shape({left: PropTypes.number,right: PropTypes.number,length: PropTypes.number,thickness: PropTypes.number}),
        right: PropTypes.shape({front: PropTypes.number,back: PropTypes.number,length: PropTypes.number,thickness: PropTypes.number}),
        left: PropTypes.shape({front: PropTypes.number,back: PropTypes.number,length: PropTypes.number,thickness: PropTypes.number})
      })
    })
  };

  state = {
    veneerData: {},
    currentStep: 1,
    veneerType: "extern",
    thickness: 3,
    length: 0,
    width: 0,
    height: 0,
    dimensions: {
      back: {
        checked: true,
        left: 0,
        right: 0,
        length: 0
      },
      front: {
        checked: true,
        left: 0,
        right: 0,
        length: 0
      },
      right: {
        checked: true,
        front: 0,
        back: 0,
        length: 0
      },
      left: {
        checked: true,
        front: 0,
        back: 0,
        length: 0
      }
    },
    assemblyType: "PP",
    layout: "Parpaing AR / Parpaing AV",
    saveLoading: false,
    showSuccessIcon: false
  };

  async componentWillMount() {
    const data = this.props.veneer
    let updateDimensions = true
    const defaultVeneerData = getVeneerDefaultData(this.props.defaultVeneerData);
    if (data && data.layout !== "" && data.dimensions) {
      const thickness = getVeneerThickness(data.dimensions)
      const dimensions = getVeneerDimensions(data.dimensions)
     
      const {width,depth} = getVeneerOverall(data, null, null, defaultVeneerData);
      const veneerType = data.veneertype ? data.veneertype : this.state.veneerType
       await this.setState({
        thickness: thickness !== 0 ? thickness : this.state.thickness,
        height: getVeneerHeight(data.dimensions),
        dimensions: dimensions,
        width: width,
        length: depth,
        veneerType: veneerType,
        currentStep : data.step === 2 || data.step === 1 ? data.step : 1,
        layout: data.layout,
        assemblyType: veneerAssemblyType(data.layout) || this.state.assemblyType
      })
      updateDimensions = false
    } else {
      if (defaultVeneerData && 
          Number.isFinite(defaultVeneerData.length) && 
          Number.isFinite(defaultVeneerData.width) && 
          Number.isFinite(defaultVeneerData.height)) {
        this.setState({
          thickness: getVeneerThickness(defaultVeneerData.dimensions),
          length: defaultVeneerData.length,
          width: defaultVeneerData.width,
          height: defaultVeneerData.height,
          layout: defaultVeneerData.layout,
          dimensions: await getVeneerDimensions(defaultVeneerData.dimensions)
        });
      }
    }
    
    this.mapDimensions(updateDimensions);

    this.debouncedMapDimensions = _.debounce(() => {
      this.mapDimensions();
    }, 1000);

    this.debouncedOnChangeDimensions = _.debounce((value, side, field) => {
      this.onChangeDimensions(value, side, field);
    }, 700);
  }

  getVeneerValidationRules = () => {
    const { parameters } = this.props;
    const validatorConfig = parameters.find(p => p.key === 'CONFIG3D_VENEER_VALIDATOR');
    return validatorConfig ? validatorConfig.value : [];
  };

  mapDimensions = (updateDimensions = true, fieldsToUpdate = "all", updateState = true) => {
    const { thickness, length, width, height, assemblyType } = this.state;
    const dimensions = _.cloneDeep(this.state.dimensions);
    const veneerType = this.state.veneerType
  
    if (updateDimensions) {
      switch (assemblyType) {
        case "PP":
          if (veneerType === "extern") {
            if (fieldsToUpdate === "all" || fieldsToUpdate.left) dimensions.left.length = length - (2 * thickness);
            if (fieldsToUpdate === "all" || fieldsToUpdate.right) dimensions.right.length = length - (2 * thickness);
            if (fieldsToUpdate === "all" || fieldsToUpdate.front) dimensions.front.length = width;
            if (fieldsToUpdate === "all" || fieldsToUpdate.back) dimensions.back.length = width;
          } else {
            if (fieldsToUpdate === "all" || fieldsToUpdate.left) dimensions.left.length = length;
            if (fieldsToUpdate === "all" || fieldsToUpdate.right) dimensions.right.length = length;
            if (fieldsToUpdate === "all" || fieldsToUpdate.front) dimensions.front.length = width + (2 * thickness);
            if (fieldsToUpdate === "all" || fieldsToUpdate.back) dimensions.back.length = width + (2 * thickness);
          }
          break;
        case "CC":
          if (veneerType === "extern") {
            if (fieldsToUpdate === "all" || fieldsToUpdate.front) dimensions.front.length = width - (2 * thickness);
            if (fieldsToUpdate === "all" || fieldsToUpdate.back) dimensions.back.length = width - (2 * thickness);
            if (fieldsToUpdate === "all" || fieldsToUpdate.left) dimensions.left.length = length;
            if (fieldsToUpdate === "all" || fieldsToUpdate.right) dimensions.right.length = length;
          } else {
            if (fieldsToUpdate === "all" || fieldsToUpdate.front) dimensions.front.length = width;
            if (fieldsToUpdate === "all" || fieldsToUpdate.back) dimensions.back.length = width;
            if (fieldsToUpdate === "all" || fieldsToUpdate.left) dimensions.left.length = length + (2 * thickness);
            if (fieldsToUpdate === "all" || fieldsToUpdate.right) dimensions.right.length = length + (2 * thickness);
          }
          break;
        case "PC":
          if (veneerType === "extern") {
            if (fieldsToUpdate === "all" || fieldsToUpdate.back) dimensions.back.length = width - (2 * thickness);
            if (fieldsToUpdate === "all" || fieldsToUpdate.left) dimensions.left.length = length - thickness;
            if (fieldsToUpdate === "all" || fieldsToUpdate.front) dimensions.front.length = width;
            if (fieldsToUpdate === "all" || fieldsToUpdate.right) dimensions.right.length = length - thickness;
          } else {
            if (fieldsToUpdate === "all" || fieldsToUpdate.back) dimensions.back.length = width;
            if (fieldsToUpdate === "all" || fieldsToUpdate.left) dimensions.left.length = length + thickness;
            if (fieldsToUpdate === "all" || fieldsToUpdate.front) dimensions.front.length = width + (2 * thickness);
            if (fieldsToUpdate === "all" || fieldsToUpdate.right) dimensions.right.length = length + thickness;
          }
          break;
        case "CP":
          if (veneerType === "extern") {
            if (fieldsToUpdate === "all" || fieldsToUpdate.front) dimensions.front.length = width - (2 * thickness);
            if (fieldsToUpdate === "all" || fieldsToUpdate.back) dimensions.back.length = width;
            if (fieldsToUpdate === "all" || fieldsToUpdate.left) dimensions.left.length = length - thickness;
            if (fieldsToUpdate === "all" || fieldsToUpdate.right) dimensions.right.length = length - thickness;
          } else {
            if (fieldsToUpdate === "all" || fieldsToUpdate.front) dimensions.front.length = width;
            if (fieldsToUpdate === "all" || fieldsToUpdate.back) dimensions.back.length = width + (2 * thickness);
            if (fieldsToUpdate === "all" || fieldsToUpdate.left) dimensions.left.length = length + thickness;
            if (fieldsToUpdate === "all" || fieldsToUpdate.right) dimensions.right.length = length + thickness;
          }
          break;
        default:
          break;
      }
  
      // Set height for all sides
      ["left", "right", "front", "back"].forEach(side => {
        if ((fieldsToUpdate === "all" || fieldsToUpdate[side])) {
          if (side === "left" || side === "right") {
            if (fieldsToUpdate === "all" || (fieldsToUpdate[side] && fieldsToUpdate[side].includes("front")))
              dimensions[side].front = height;
            if (fieldsToUpdate === "all" || (fieldsToUpdate[side] && fieldsToUpdate[side].includes("back")))
              dimensions[side].back = height;
          } else {
            if (fieldsToUpdate === "all" || (fieldsToUpdate[side] && fieldsToUpdate[side].includes("left")))
              dimensions[side].left = height;
            if (fieldsToUpdate === "all" || (fieldsToUpdate[side] && fieldsToUpdate[side].includes("right")))
              dimensions[side].right = height;
          }
        }
      });
    }
  
    const veneerData = this.getVeneerData(this.state, dimensions);
    
    if (updateState) {
      this.setState({ dimensions, veneerData: length === 0 && width === 0 ? {} : veneerData });
    } else return dimensions;

  };


  calculateOverallDimensions = (side, field, value) => {
    const { thickness, assemblyType, veneerType } = this.state;
    let newLength = this.state.length;
    let newWidth = this.state.width;
    
    if (field !== "length") return { length: newLength, width: newWidth };
    
    const dimensions = this.state.dimensions;

    // remove unchecked sides
    const newDimensions = _.pickBy(dimensions, (side) => side.checked);

    const leftLength = newDimensions?.left?.checked ? (side === "left" ? value : newDimensions?.left?.length) : -Infinity;
    const rightLength = newDimensions?.right?.checked ? (side === "right" ? value : newDimensions?.right?.length) : -Infinity;
    const frontLength = newDimensions?.front?.checked ? (side === "front" ? value : newDimensions?.front?.length) : -Infinity;
    const backLength = newDimensions?.back?.checked ? (side === "back" ? value : newDimensions?.back?.length) : -Infinity;

    let maxSideLength, maxFrontBackLength;
    
    if (leftLength === -Infinity && rightLength === -Infinity) {
      maxSideLength = side === "left" || side === "right" ? value : 0;
    } else {
      maxSideLength = Math.max(leftLength, rightLength);
    }
    
    if (frontLength === -Infinity && backLength === -Infinity) {
      maxFrontBackLength = side === "front" || side === "back" ? value : 0;
    } else {
      maxFrontBackLength = Math.max(frontLength, backLength);
    }
    
    // Calculate based on assemblyType and veneerType
    switch (assemblyType) {
      case "PP":
        if (side === "left" || side === "right") {
          if (veneerType === "extern") {
            newLength = maxSideLength + (2 * thickness);
          } else {
            newLength = maxSideLength;
          }
        } else if (side === "front" || side === "back") {
          if (veneerType === "extern") {
            newWidth = maxFrontBackLength;
          } else {
            newWidth = maxFrontBackLength - (2 * thickness);
          }
        }
        break;
      case "CC":
        if (side === "left" || side === "right") {
          if (veneerType === "extern") {
            newLength = maxSideLength;
          } else {
            newLength = maxSideLength - (2 * thickness);
          }
        } else if (side === "front" || side === "back") {
          if (veneerType === "extern") {
            newWidth = maxFrontBackLength + (2 * thickness);
          } else {
            newWidth = maxFrontBackLength;
          }
        }
        break;
      case "PC":
        if (side === "left" || side === "right") {
          if (veneerType === "extern") {
            newLength = maxSideLength + thickness;
          } else {
            newLength = maxSideLength - thickness;
          }
        } else if (side === "front" || side === "back") {
          if (side === "front") {
            if (veneerType === "extern") {
              newWidth = frontLength;
            } else {
              newWidth = frontLength - (2 * thickness);
            }
          } else {
            if (veneerType === "extern") {
              newWidth = backLength + (2 * thickness);
            } else {
              newWidth = backLength;
            }
          }
        }
        break;
      case "CP":
        if (side === "left" || side === "right") {
          if (veneerType === "extern") {
            newLength = maxSideLength + thickness;
          } else {
            newLength = maxSideLength - thickness;
          }
        } else if (side === "front" || side === "back") {
          if (side === "front") {
            if (veneerType === "extern") {
              newWidth = frontLength + (2 * thickness);
            } else {
              newWidth = frontLength;
            }
          } else {
            if (veneerType === "extern") {
              newWidth = backLength;
            } else {
              newWidth = backLength - (2 * thickness);
            }
          }
        }
        break;
      default:
        break;
    }
    
    return { length: newLength, width: newWidth };
  };

  processVeneerDimensions = (dimensions, thickness, removeChecked = true) => {
    // Filter and transform dimensions based on checked status
    return _.pickBy(
      _.mapValues(dimensions, (side) => {
        // If side is not checked and we want to remove unchecked sides, return null (will be filtered out by pickBy)
        if (removeChecked && !side.checked) return null;
        
        // If side is checked or we're keeping all sides, remove checked property and add thickness
        const { checked, ...rest } = side;
        return { ...rest, thickness: thickness };
      }),
      // Filter out null values (unchecked sides)
      value => value !== null
    );
  };

  getVeneerData = (veneer, dimensions) => {
    let veneerData = {...veneer};
  
    veneerData.dimensions = this.processVeneerDimensions(dimensions, this.state.thickness);
  
    veneerData.assemblyType = this.state.assemblyType;
    veneerData.layout = this.state.layout;
    veneerData.width = this.state.width;
    veneerData.length = this.state.length;
    veneerData.veneertype = this.state.veneerType;
    const infoTitle = this.getTitle(veneerData.dimensions, veneerData.width, veneerData.length);
    veneerData.titleInfo = infoTitle
    return veneerData;
  };

  monumentHasSides = (side,type = null) => {
    // vérifie si le côté existe: epaisseur et longueur non vide et une des hauteurs non vide
    const tabSides = side !== "all" ? [side] : ["left", "right", "back", "front"];
    const data =  type
    return _.some(tabSides, side => {
      const { left, right, back, front, length, thickness } = _.get(data,side,{});
      return (
        !!length && !!thickness && (!!left || !!right || !!back || !!front)
      );
    });
  };

  getTitle = (data,width,length) => { // Récupère le bon titre à afficher selon le nb de placages, les tailles, epaisseurs et 
    const t = this.context;
    if (this.monumentHasSides("all",data)){ // Si au moins un des côtés est présent
      let nbSides = 0;
      ["back", "left", "right", "front"].forEach(side => {
        if (this.monumentHasSides(side,data)) { // ne prend en compte que les côtés ayant une longueur/hauteur
          nbSides++;
        }
      })
      const title = nbSides ===1 ? "1 placage": `${nbSides} ${ t("config3d_monument_modal_veneer_title_on") || "placages" }` ; // 1 ou plusieurs placages 
      const subtitle = nbSides > 1 && length !== 0 && width !== 0 ? `${ t("config3d_monument_modal_veneer_subtitle") || "Dimensions hors tout : "} ${length} ${ t("config3d_monument_modal_veneer_measurementunit") || "cm"}  x ${width} ${ t("config3d_monument_modal_veneer_measurementunit") || "cm"}`:"";
      return({ title:title, subtitle:subtitle });
    }
    return ({
      title: t("config3d_monument_modal_veneer_title_off") || "Ajouter les placages souhaités",
      subtitle:""
    });
  }

  save = () => {
    const data = this.state.veneerData

    const veneer = {...this.props.veneer,layout:data.layout,dimensions:data.dimensions,display:this.props.display,
      details:data.titleInfo.subtitle, step: this.state.currentStep, assemblyType: data.assemblyType, width: data.width, length: data.length, veneertype: data.veneertype};
    this.props.actions.updateVeneer(veneer,this.props.display);
    this.props.onClose();
  };

  saveDefaultVeneer = async () => {
    this.setState({saveLoading: true});
    const data = this.state.veneerData
    let newDimensions = this.mapDimensions(true, "all", false);
    newDimensions = this.processVeneerDimensions(newDimensions, this.state.thickness, false);

    try {
      const defaultVeneerObj = {
        thickness: this.state.thickness, 
        length: this.state.length, 
        width: this.state.width, 
        height: this.state.height,
        dimensions: newDimensions,
        layout: data.layout,
        details: data.titleInfo.subtitle,
        veneertype: data.veneertype
      };
  
      await this.props.actions.saveDefaultVeneer(defaultVeneerObj, getOrganization().reference);

      // mybe show a success message
      this.setState({saveLoading: false, showSuccessIcon: true});
      
      setTimeout(() => {
        this.setState({showSuccessIcon: false});
      }, 3000);
    } catch (error) {
      console.error('Failed to save default veneer:', error);
      this.setState({saveLoading: false});
    }
  };

  onChangeSteps = (e) => {
    if (e.target.value === "2") {
      this.setState({currentStep: 1});
    }
    this.setState({currentStep: parseInt(e.target.value)});
  };

  onChangeVeneerType = (e) => {
    this.setState({veneerType: e.target.value}, () => {
      this.debouncedMapDimensions();
    });
  }

  onChangeThickness = (value) => {
    this.setState({thickness: value}, () => {
      const validationRules = this.applyValidationRules();
      const {length, width, height} = this.state.veneerData
      if (width > validationRules.lengthMax) this.setState({width: validationRules.lengthMax});
      if (length > validationRules.lengthMax) this.setState({length: validationRules.lengthMax});
      if (height > validationRules.heightMax) this.setState({height: validationRules.heightMax});
      this.debouncedMapDimensions();
    });
  }
  
  onchangeLength = (value) => {
    const validationRules = this.applyValidationRules();
    if (value > validationRules.lengthMax) {
        this.setState({ length: validationRules.lengthMax }, () => {
            this.debouncedMapDimensions();
        });
        return;
    }
    if (value < validationRules.lengthMin) {
        this.setState({ length: validationRules.lengthMin }, () => {
            this.debouncedMapDimensions();
        });
        return;
    }
    this.setState({ length: value }, () => {
        this.debouncedMapDimensions();
    });
  }
  
  onchangeWidth = (value) => {
    const validationRules = this.applyValidationRules();

    if (value > validationRules.lengthMax) {
        this.setState({ width: validationRules.lengthMax }, () => {
            this.debouncedMapDimensions();
        });
        return;
    }
    if (value < validationRules.lengthMin) {
        this.setState({ width: validationRules.lengthMin }, () => {
            this.debouncedMapDimensions();
        });
        return;
    }
    this.setState({ width: value }, () => {
        this.debouncedMapDimensions();
    });
  }
  
  onchangeHeight = (value) => {
    const validationRules = this.applyValidationRules();
    if (value > validationRules.heightMax) {
        this.setState({ height: validationRules.heightMax }, () => {
            this.debouncedMapDimensions();
        });
        return;
    }
    if (value < validationRules.heightMin) {
        this.setState({ height: validationRules.heightMin }, () => {
            this.debouncedMapDimensions();
        });
        return;
    }
    this.setState({ height: value }, () => {
        this.debouncedMapDimensions();
    });
  };

  onchangeShowCheckbox = (e, side) => {
    const dimensions = _.cloneDeep(this.state.dimensions);
    dimensions[side].checked = e.target.checked;
    const fieldsToUpdate = { [side]: ["front", "back", "left", "right"] };
    this.setState( { dimensions}, () => this.mapDimensions(true, fieldsToUpdate));
  }

  onChangeAssemblyType = (e, side) => {
    const isParpaing = e.target.value === "parpaing";
    const assemblyChar = isParpaing ? "P" : "C";
    const layoutText = isParpaing ? "Parpaing" : "Closoir";
  
    const parts = this.state.layout.split(" / ");
    let frontPart = parts[1] || "";
    let backPart = parts[0] || "";
  
    if (side === "back") {
      backPart = `${layoutText} AR`;
      this.setState({
        assemblyType: this.state.assemblyType.slice(0, -1) + assemblyChar,
        layout: `${backPart} / ${frontPart}`
      }, () => this.mapDimensions());
    } else if (side === "front") {
      frontPart = `${layoutText} AV`;
      this.setState({
        assemblyType: assemblyChar + this.state.assemblyType.slice(1),
        layout: `${backPart} / ${frontPart}`
      }, () => this.mapDimensions());
    }
  }

  onChangeDimensions = (value, side, field) => {
    const dimensions = _.cloneDeep(this.state.dimensions);
    dimensions[side][field] = value;
    
    const { length, width } = this.calculateOverallDimensions(side, field, value);

    this.setState(
      {
        dimensions, 
        length: field === "length" ? length : this.state.length, 
        width: field === "length" ? width : this.state.width 
      }, 
      () => this.mapDimensions(false)
    );
  }

  applyValidationRules = () => {
    const rules = this.getVeneerValidationRules();
    if (!rules) return;
  
    const { commonRules, rules: thicknessRules } = rules;
    const { thickness } = this.state;
  
    // Get all allowed thickness values
    const allowedThicknesses = thicknessRules.reduce((acc, rule) => {
      if (Array.isArray(rule.epaisseursAutor)) {
        return [...acc, ...rule.epaisseursAutor];
      }
      return [...acc, rule.epaisseursAutor];
    }, []);
  
    // Find min and max thickness
    const thicknessMin = Math.min(...allowedThicknesses);
    const thicknessMax = Math.max(...allowedThicknesses);
  
    // Find specific rule based on current thickness
    const specificRule = thicknessRules.find(rule => {
      if (Array.isArray(rule.epaisseursAutor)) {
        return rule.epaisseursAutor.includes(thickness);
      }
      return rule.epaisseursAutor === thickness;
    });
  
    // Update length constraints based on thickness
    const lengthMin = specificRule?.longueurMin || 10;
    const lengthMax = specificRule?.longueurMax || 480;
  
    return {
      heightMin: commonRules.hauteurMin,
      heightMax: commonRules.hauteurMax,
      lengthMin,
      lengthMax,
      thicknessMin,
      thicknessMax
    };
  };

  render() {
    const t = this.context;
    const isMobile = isSmartphone();
    const validationRules = this.applyValidationRules();
    const canMoveToStep2 = this.state.thickness > 0 && this.state.length > 0 && this.state.width > 0 && this.state.height > 0;
    const lengthMin = validationRules && validationRules.lengthMin ? validationRules.lengthMin : 10,
          lengthMax = validationRules && validationRules.lengthMax ? validationRules.lengthMax : 480,
          heightMin= validationRules && validationRules.heightMin ? validationRules.heightMin : 0,
          heightMax= validationRules && validationRules.heightMax ? validationRules.heightMax : 120,
          thicknessMin = validationRules && validationRules.thicknessMin ? validationRules.thicknessMin : 3,
          thicknessMax = validationRules && validationRules.thicknessMax ? validationRules.thicknessMax : 10;

    return (
          <div className="ModalVeneerOptionPanel StepVeneer background-page" >
            {/* Section 1 */}
            <div className="FirstStepVeneer text-dark">
              <div className="MediumTitle" style={{ display:"flex", gap: "16px" }}>
                <div className="CustomProperty" style={{ display:"flex", gap:"1rem", marginBottom: "0" }}>
                  <label className="radio">
                    <input type="radio" name="currentStep" className="radio-input" value="1" id="step1" onChange={this.onChangeSteps} checked={this.state.currentStep === 1} />
                    <label className="radio-label" htmlFor="step1">
                      <span className="radio-border"></span>
                      { t("config3d_monument_modal_veneer_default") }
                    </label>
                  </label>
                </div>

                <div className="SmallTitle" style={{ display:"flex", gap: "12px", position: "relative"}}>
                  {this.state.currentStep === 2 && <div className="disable-container"></div>}
                  <div className="CustomProperty" style={{ display:"flex", gap:"1rem", marginBottom: "0" }}>
                    <label className="radio">
                      <input type="radio" name="veneerType" className="radio-input" value="extern" id="extern" onChange={this.onChangeVeneerType} checked={this.state.veneerType === "extern"} />
                      <label className="radio-label small-radio-label" htmlFor="extern">
                        <span className="radio-border"></span>
                        { t("config3d_monument_modal_veneer_outside") }
                      </label>
                    </label>
                  </div>
                  <div className="CustomProperty" style={{ display:"flex", gap:"1rem", marginBottom: "0" }}>
                    <label className="radio">
                      <input type="radio" name="veneerType" className="radio-input" value="intern" id="intern" onChange={this.onChangeVeneerType} checked={this.state.veneerType === "intern"} />
                      <label className="radio-label small-radio-label" htmlFor="intern">
                        <span className="radio-border"></span>
                        { t("config3d_monument_modal_veneer_interior") }
                      </label>
                    </label>
                  </div>
                </div>
              </div>

              <div style={{ display:"flex", gap:"16px", padding: "0 80px" }}>
                <div className="CustomProperty">
                  <label className="Label">
                  { t("config3d_monument_modal_veneer_thickness") || "Epaisseur" }
                  </label>
                  <div className="input-wrapper">
                    <InputNumber 
                      className="custom-input-field"
                      min={thicknessMin}
                      max={thicknessMax}
                      value={this.state.thickness}
                      autoSelect={true}
                      onValueChange={this.onChangeThickness}/>
                    <span className="custom-input-addon">
                      { t("config3d_monument_modal_frame_measurementunit") || "cm" }
                    </span>
                  </div>
                </div>

                <div className="section-disable" style={{ display:"flex", gap:"16px" }}>
                  {this.state.currentStep === 2 && <div className="disable-container"></div>}
                  <div className="CustomProperty">
                    <label className="Label">
                      { t("config3d_monument_modal_veneer_width") }
                    </label>
                    <div className="input-wrapper">
                      <InputNumber 
                        className="custom-input-field" 
                        // min={lengthMin}
                        // max={lengthMax}
                        autoSelect={true}
                        value={this.state.width}
                        onValueChange={this.onchangeWidth}/>
                      <span className="custom-input-addon">
                        { t("config3d_monument_modal_frame_measurementunit") || "cm" }
                      </span>
                    </div>
                  </div>
                  
                  <div className="CustomProperty">
                    <label className="Label">
                      { t("config3d_monument_modal_veneer_depth") }
                    </label>
                    <div className="input-wrapper">
                      <InputNumber 
                        className="custom-input-field"
                        // min={lengthMin}
                        // max={lengthMax}
                        value={this.state.length}
                        autoSelect={true}
                        onValueChange={this.onchangeLength}/>
                      <span className="custom-input-addon">
                        { t("config3d_monument_modal_frame_measurementunit") || "cm" }
                      </span>
                    </div>
                  </div>
                  
                  <div className="CustomProperty">
                    <label className="Label">
                      { t("config3d_monument_modal_veneer_height") }
                    </label>
                    <div className="input-wrapper">
                      <InputNumber 
                        className="custom-input-field" 
                        // min={heightMin}
                        // max={heightMax}
                        autoSelect={true}
                        value={this.state.height}
                        onValueChange={this.onchangeHeight}/>
                      <span className="custom-input-addon">
                        { t("config3d_monument_modal_frame_measurementunit") || "cm" }
                      </span>
                    </div>
                  </div>
                  <div className="CustomProperty">
                    <label className="Label">
                      { t("config3d_monument_modal_veneer_default_button") }
                    </label>
                    <div style={{textAlign: "center"}}>
                      <button
                        className="ButtonDefVeneer"
                        onClick={this.saveDefaultVeneer}
                        disabled={this.state.saveLoading}
                      >
                        <img src={validButton}></img>
                      </button>
                      <img 
                        src={succcessIcon} 
                        className={`success-icon ${this.state.showSuccessIcon ? 'show' : ''}`} 
                      />
                    </div>
                  </div>
                </div>
             
              </div>
            </div>

            {/* Section 2 */}
            <div className="SecondStepVeneer text-dark">
              {/* Title */}
              <div className="MediumTitle" style={{ display:"flex", gap: "16px" }}>
                <div className="CustomProperty secondStep">
                  <label className="radio" {...(!canMoveToStep2 && { "data-tooltip": t("config3d_monument_modal_not_move_to_step_2_error") })}>
                    <input type="radio" name="currentStep" className="radio-input" value="2" id="step2" onChange={this.onChangeSteps} checked={this.state.currentStep === 2} disabled={!canMoveToStep2} />
                    <label className="radio-label" htmlFor="step2">
                      <span className="radio-border"></span>
                      { t("config3d_monument_modal_veneer_custom") || "2- Je personnalise pièce par pièce" }
                    </label>
                  </label>
                </div>
              </div>
              <div className="section-disable">
                {this.state.currentStep === 1 && <div className="disable-container"></div>}
                {/* Placage Arrière */}
                <div className="back-veneer">
                  <div className="line">
                    <div className="SmallTitle" style={{ display:"flex", gap: "12px"}}>
                      <div className="CustomProperty" style={{ display:"flex", gap:"1rem", marginBottom: "0" }}>
                        <label className="checkbox">
                          <input type="checkbox" className="custom-checkbox" checked={this.state.dimensions.back.checked} onChange={(e) => this.onchangeShowCheckbox(e, "back")} id="checkbox_back" />
                          <label htmlFor="checkbox_back">
                            <span>
                              { t("config3d_monument_modal_veneer_back") }
                            </span>
                          </label>
                        </label>
                      </div>
                      <div className={!this.state.dimensions.back.checked ? "CustomProperty disable-block" : "CustomProperty"} style={{ display:"flex", gap:"1rem", marginBottom: "0" }}>
                        <label className="radio">
                          <input type="radio" name="assemblyType" className="radio-input" value="parpaing" id="parpaing" checked={this.state.assemblyType.endsWith("P")} onChange={(e) => this.onChangeAssemblyType(e, "back")} />
                          <label className="radio-label small-radio-label assembly-type-radio" htmlFor="parpaing">
                            <span className="radio-border"></span>
                            { t("config3d_monument_modal_veneer_layout_parpaing") }
                          </label>
                        </label>
                      </div>
                      <div className={!this.state.dimensions.back.checked ? "CustomProperty disable-block" : "CustomProperty"} style={{ display:"flex", gap:"1rem", marginBottom: "0" }}>
                        <label className="radio">
                          <input type="radio" name="assemblyType" className="radio-input" value="closoir" id="closoir" checked={this.state.assemblyType.endsWith("C")} onChange={(e) => this.onChangeAssemblyType(e, "back")} />
                          <label className="radio-label small-radio-label assembly-type-radio" htmlFor="closoir">
                            <span className="radio-border"></span>
                            { t("config3d_monument_modal_veneer_layout_closoir") }
                          </label>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className = "line">
                    <div className={!this.state.dimensions.back.checked ? "SmallTitle disable-block" : "SmallTitle"} style={{ display:"flex", gap: "12px"}}>
                      <div className="CustomProperty">
                        <label className="Label">
                          { t("config3d_monument_modal_veneer_length") }
                        </label>
                        <div className="input-wrapper">
                          <InputNumber 
                            className="custom-input-field"
                            min={lengthMin}
                            max={lengthMax}
                            autoSelect={true}
                            value={this.state.dimensions.back.length}
                            disabled={!this.state.dimensions.back.checked}
                            onValueChange={(e) => this.debouncedOnChangeDimensions(e, "back", "length")}/>
                          <span className="custom-input-addon">
                            { t("config3d_monument_modal_frame_measurementunit") || "cm" }
                          </span>
                        </div>
                      </div>
                      <div className="CustomProperty">
                        <label className="Label">
                          { t("config3d_monument_modal_veneer_height_left") }
                        </label>
                        <div className="input-wrapper">
                          <InputNumber 
                            className="custom-input-field"
                            min={heightMin}
                            max={heightMax}
                            disabled={!this.state.dimensions.back.checked}
                            autoSelect={true}
                            value={this.state.dimensions.back.left}
                            onValueChange={(e) => this.debouncedOnChangeDimensions(e, "back", "left")}/>
                          <span className="custom-input-addon">
                            { t("config3d_monument_modal_frame_measurementunit") || "cm" }
                          </span>
                        </div>
                      </div>
                      <div className="CustomProperty">
                        <label className="Label">
                          { t("config3d_monument_modal_veneer_height_right") }
                        </label>
                        <div className="input-wrapper">
                          <InputNumber 
                            className="custom-input-field"
                            min={heightMin}
                            max={heightMax}
                            disabled={!this.state.dimensions.back.checked}
                            autoSelect={true}
                            value={this.state.dimensions.back.right}
                            onValueChange={(e) => this.debouncedOnChangeDimensions(e, "back", "right")}/>
                          <span className="custom-input-addon">
                            { t("config3d_monument_modal_frame_measurementunit") || "cm" }
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Placage gauche et droit et lumi image preview */}
                <div className="left-right-veneer">
                  {/* Placage Gauche */}
                  <div>
                    <div className="SmallTitle" style={{ gap: "12px"}}>
                      <div className="CustomProperty" style={{ display:"flex", gap:"1rem", marginBottom: "0" }}>
                        <label className="checkbox">
                          <input type="checkbox" className="custom-checkbox" checked={this.state.dimensions.left.checked} onChange={(e) => this.onchangeShowCheckbox(e, "left")} id="checkbox_left"/>
                          <label htmlFor="checkbox_left">
                            <span>
                              { t("config3d_monument_modal_veneer_left") }
                            </span>
                          </label>
                        </label>
                      </div>
                      <div  className={!this.state.dimensions.left.checked ? "disable-block" : ""}>
                      <div className="CustomProperty" style={{ display:"flex", gap:"1rem", marginTop: "5px"}}>
                        <label className="Label">
                          { t("config3d_monument_modal_veneer_length") }
                        </label>
                        <div className="input-wrapper">
                          <InputNumber 
                            className="custom-input-field"
                            min={lengthMin}
                            max={lengthMax}
                            autoSelect={true}
                            value={this.state.dimensions.left.length}
                            disabled={!this.state.dimensions.left.checked}
                            onValueChange={(e) => this.debouncedOnChangeDimensions(e, "left", "length")}/>
                          <span className="custom-input-addon">
                            { t("config3d_monument_modal_frame_measurementunit") || "cm" }
                          </span>
                        </div>
                      </div>
                      <div className="CustomProperty" style={{ display:"flex", gap:"1rem", marginTop: "5px"}}>
                        <label className="Label">
                          { t("config3d_monument_modal_veneer_height_back") }
                        </label>
                        <div className="input-wrapper">
                          <InputNumber 
                            className="custom-input-field"
                            min={heightMin}
                            max={heightMax}
                            value={this.state.dimensions.left.back}
                            disabled={!this.state.dimensions.left.checked}
                            autoSelect={true}
                            onValueChange={(e) => this.debouncedOnChangeDimensions(e, "left", "back")}/>
                          <span className="custom-input-addon">
                            { t("config3d_monument_modal_frame_measurementunit") || "cm" }
                          </span>
                        </div>
                      </div>
                      <div className="CustomProperty" style={{ display:"flex", gap:"1rem", marginTop: "5px"}}>
                        <label className="Label">
                          { t("config3d_monument_modal_veneer_height_front")}
                        </label>
                        <div className="input-wrapper">
                          <InputNumber 
                            className="custom-input-field"
                            min={heightMin}
                            max={heightMax}
                            value={this.state.dimensions.left.front}
                            disabled={!this.state.dimensions.left.checked}
                            autoSelect={true}
                            onValueChange={(e) => this.debouncedOnChangeDimensions(e, "left", "front")}/>
                          <span className="custom-input-addon">
                            { t("config3d_monument_modal_frame_measurementunit") || "cm" }
                          </span>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>

                  {/* Lumi Image Preview */}
                  <div className="veneerImage">
                    <LumiImagePreview 
                      type="veneer" 
                      veneerData={this.state.veneerData} 
                      containerStyle={{ width: "47vh", height: "100%", display: "flex" }} 
                      imageStyle={{margin: "auto", marginTop: "unset !important", height: "100%"}} 
                      selectStyle={{ width: isMobile ? "50%" : "30%", ...(isMobile && { height: "3rem" }) }} 
                      cameraView="normal"
                      onCameraViewChange={() => {}}
                    />
                  </div>

                  {/* Placage Droit */}
                  <div>
                    <div className="SmallTitle" style={{ gap: "12px"}}>
                      <div className="CustomProperty" style={{ display:"flex", gap:"1rem", marginBottom: "0" }}>
                        <label className="checkbox">
                          <input type="checkbox" className="custom-checkbox" checked={this.state.dimensions.right.checked} onChange={(e) => this.onchangeShowCheckbox(e, "right")} id="checkbox_right"/>
                          <label htmlFor="checkbox_right">
                            <span>
                              { t("config3d_monument_modal_veneer_right") }
                            </span>
                          </label>
                        </label>
                      </div>
                      <div className={ !this.state.dimensions.right.checked ? "disable-block" : ""}>
                      <div className="CustomProperty" style={{ display:"flex", gap:"1rem", marginTop: "5px"}}>
                        <label className="Label">
                          { t("config3d_monument_modal_veneer_length") }
                        </label>
                        <div className="input-wrapper">
                          <InputNumber 
                            className="custom-input-field" 
                            min={lengthMin}
                            max={lengthMax}
                            value={this.state.dimensions.right.length}
                            disabled={!this.state.dimensions.right.checked}
                            autoSelect={true}
                            onValueChange={(e) => this.debouncedOnChangeDimensions(e, "right", "length")}/>
                          <span className="custom-input-addon">
                            { t("config3d_monument_modal_frame_measurementunit") || "cm" }
                          </span>
                        </div>
                      </div>
                      <div className="CustomProperty" style={{ display:"flex", gap:"1rem", marginTop: "5px"}}>
                        <label className="Label">
                          { t("config3d_monument_modal_veneer_height_back") }
                        </label>
                        <div className="input-wrapper">
                          <InputNumber 
                            className="custom-input-field" 
                            min={heightMin}
                            max={heightMax}
                            value={this.state.dimensions.right.back}
                            disabled={!this.state.dimensions.right.checked}
                            autoSelect={true}
                            onValueChange={(e) => this.debouncedOnChangeDimensions(e, "right", "back")}/>
                          <span className="custom-input-addon">
                            { t("config3d_monument_modal_frame_measurementunit") || "cm" }
                          </span>
                        </div>
                      </div>
                      <div className="CustomProperty" style={{ display:"flex", gap:"1rem", marginTop: "5px"}}>
                        <label className="Label">
                          { t("config3d_monument_modal_veneer_height_front") }
                        </label>
                        <div className="input-wrapper">
                          <InputNumber 
                            className="custom-input-field" 
                            min={heightMin}
                            max={heightMax}
                            value={this.state.dimensions.right.front}
                            disabled={!this.state.dimensions.right.checked}
                            autoSelect={true}
                            onValueChange={(e) => this.debouncedOnChangeDimensions(e, "right", "front")}/>
                          <span className="custom-input-addon">
                            { t("config3d_monument_modal_frame_measurementunit") || "cm" }
                          </span>
                        </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Placage Avant */}
                <div className ="front-veneer" >
                  <div className="line">
                    <div className="SmallTitle" style={{ display:"flex", gap: "12px"}}>
                      <div className="CustomProperty" style={{ display:"flex", gap:"1rem", marginBottom: "0" }}>
                        <label className="checkbox">
                          <input type="checkbox" className="custom-checkbox" checked={this.state.dimensions.front.checked} onChange={(e) => this.onchangeShowCheckbox(e, "front")} id="checkbox_front"/>
                          <label htmlFor="checkbox_front">
                            <span>
                              { t("config3d_monument_modal_veneer_front") }
                            </span>
                          </label>
                        </label>
                      </div>
                      <div className={!this.state.dimensions.front.checked ? "CustomProperty disable-block" : "CustomProperty"} style={{ display:"flex", gap:"1rem", marginBottom: "0" }}>
                        <label className="radio">
                          <input type="radio" name="assemblyTypeFront" className="radio-input" value="parpaing" id="parpaing_avant" checked={this.state.assemblyType.startsWith("P")} onChange={(e) => this.onChangeAssemblyType(e, "front")} />
                          <label className="radio-label small-radio-label assembly-type-radio" htmlFor="parpaing_avant">
                            <span className="radio-border"></span>
                            { t("config3d_monument_modal_veneer_layout_parpaing") }
                          </label>
                        </label>
                      </div>
                      <div className={!this.state.dimensions.front.checked ? "CustomProperty disable-block" : "CustomProperty"}>
                        <label className="radio">
                          <input type="radio" name="assemblyTypeFront" className="radio-input" value="closoir" id="closoir_avant" checked={this.state.assemblyType.startsWith("C")} onChange={(e) => this.onChangeAssemblyType(e, "front")} />
                          <label className="radio-label small-radio-label assembly-type-radio" htmlFor="closoir_avant">
                            <span className="radio-border"></span>
                            { t("config3d_monument_modal_veneer_layout_closoir") }
                          </label>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="line">
                    <div className="SmallTitle" style={{ display:"flex", gap: "12px"}}>
                      <div className={!this.state.dimensions.front.checked ? "CustomProperty disable-block" : "CustomProperty"}>
                        <label className="Label">
                          { t("config3d_monument_modal_veneer_length") }
                        </label>
                        <div className="input-wrapper">
                          <InputNumber 
                            className="custom-input-field" 
                            min={lengthMin}
                            max={lengthMax}
                            value={this.state.dimensions.front.length}
                            autoSelect={true}
                            disabled={!this.state.dimensions.front.checked}
                            onValueChange={(e) => this.debouncedOnChangeDimensions(e, "front", "length")}/>
                          <span className="custom-input-addon">
                            { t("config3d_monument_modal_frame_measurementunit") || "cm" }
                          </span>
                        </div>
                      </div>
                      <div className={!this.state.dimensions.front.checked ? "CustomProperty disable-block" : "CustomProperty"}>
                        <label className="Label">
                          { t("config3d_monument_modal_veneer_height_left") }
                        </label>
                        <div className="input-wrapper">
                          <InputNumber 
                            className="custom-input-field" 
                            min={heightMin}
                            max={heightMax}
                            value={this.state.dimensions.front.left}
                            autoSelect={true}
                            disabled={!this.state.dimensions.front.checked}
                            onValueChange={(e) => this.debouncedOnChangeDimensions(e, "front", "left")}/>
                          <span className="custom-input-addon">
                            { t("config3d_monument_modal_frame_measurementunit") || "cm" }
                          </span>
                        </div>
                      </div>
                      <div className={!this.state.dimensions.front.checked ? "CustomProperty disable-block" : "CustomProperty"}>
                        <label className="Label">
                          { t("config3d_monument_modal_veneer_height_right") }
                        </label>
                        <div className="input-wrapper">
                          <InputNumber 
                            className="custom-input-field" 
                            min={heightMin}
                            max={heightMax}
                            value={this.state.dimensions.front.right}
                            autoSelect={true}
                            disabled={!this.state.dimensions.front.checked}
                            onValueChange={(e) => this.debouncedOnChangeDimensions(e, "front", "right")}/>
                          <span className="custom-input-addon">
                            { t("config3d_monument_modal_frame_measurementunit") || "cm" }
                          </span>
                        </div>
                      </div>
                      {/* Button Valider */}
                      <div>
                      <button 
                        className={"button-primary text-cta button-medium valid-button"}
                        onClick={this.save}
                      >
                        <span>{ t("config3d_monument_modal_veneer_validatebutton") || "Valider"}</span>
                      </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
          
    );
  }
}

export const VeneerConfigure = connect(
  (state) => ({
    monument: state.configurator.current.configuration.monument,
    veneer: state.configurator.current.configuration.veneer,
    parameters: state.configurator.parameters,
    defaultVeneerData: state.configurator.current.options.defaultVeneer,
  }),
  dispatch => ({
    actions: bindActionCreators(updateConfigurationActions, dispatch)
  })
)(VeneerConfigureComponent);
