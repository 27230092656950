import * as types from './actionTypes';
import * as api from '../services/api.service';
import history from "../history";
import {closeMonument} from './catalogActions';
import {updateFrameAndVeneerScenes} from './updateConfigurationActions';
import {displayStandardConfigImage} from '../services/image.fct.service';
import { getDesignTypeConfiguration, getMonumentCategory, isConfigurationRequested } from '../services/utils.service';
import {getOwnerEmail} from '../pages/family_space/utils/utils';
import {checkLoadedFonts} from '../services/generateEngravings.service';
import { prepareData } from '../pages/family/components/FpriceList';

export function initConfiguration(monument,config,family=false,ref = false,type="", graniteRef=null) {
  type = family ? "family":type;
  if (typeof monument.graniteMain == "string"){ 
    graniteRef = graniteRef == null ? monument.graniteMain:graniteRef;
  }
  if (ref){
    return _initConfiguration({monumentRef:monument}, false,config,family,type,graniteRef);
  }else{
    let categories = getMonumentCategory();
    if(monument in categories){
      const category = monument;
      monument = {available: true,
        category: category,
        graniteMainId: 25,
        preconfId: false,
        reference: category}
    }
    return _initConfiguration({monument}, false,config,family,type,graniteRef);
  }
}

export function initConfigurationFromExtranet(monumentRef) {
  // On remplace l'historique dans ce cas pour éviter qu'un retour en arrière recrée une configuration
  // et nous redirige à nouveau sur la configuration
  return _initConfiguration({monumentRef}, true)
}
export function initConfigurationFromSelectionRef(selectionRef,family = false,startPage = 'ffinalisation',type="") {
  // On remplace l'historique dans ce cas pour éviter qu'un retour en arrière recrée une configuration
  // et nous redirige à nouveau sur la configuration
  if (family){
    return _initConfiguration({selectionRef}, true,{startPage:{value:startPage}},family,"finalisation");
  }else{
    return _initConfiguration({selectionRef}, true, false, type);
  }
}

function _initConfiguration(payload, replace,config = false,family = false,type="", graniteRef=null) {

  const initPage = config && config.startPage ?  config.startPage.value:'monument';
  payload.configType = type;
  if (graniteRef){
    payload.configwithgranit = true
  }
  const designType = getDesignTypeConfiguration(config);
  payload.designType = designType;

  payload.graniteRef = graniteRef;

  if (type === "family") {
    const emailOwner = getOwnerEmail();
    if (emailOwner) {payload.newfamilyInfos = {email:emailOwner}}
  }

  return (dispatch, getState) => {
    dispatch({
      type: types.CONFIGURATION_INIT_START,
      payload
    });
    api.post(`/api/configuration`, payload)
      .then(result => {
        dispatch(closeMonument());
        dispatch({
          type: types.CONFIGURATION_INIT_SUCCESS,
          payload: result
        });
        if(family){
          history[replace ? 'replace' : 'push'](`/famille/${family}/${result.reference}/${initPage}`);
        }else{
          history[replace ? 'replace' : 'push'](`/configuration/${result.reference}/${initPage}`);
        }
      })
      .catch(error => {
        dispatch({
          type: types.CONFIGURATION_INIT_FAILURE,
          payload: {
            error: error.message
          }
        });
      });
  }
}

export  function getConfiguration(reference) {
  if(reference == "undefined"){
    return  (dispatch, getState) => { dispatch({
        type: types.CONFIGURATION_GET_FAILURE,
        payload: {
          error: "configuration undefined"
        }
      });
    }
  }
  return (dispatch, getState) => {
    dispatch({
      type: types.CONFIGURATION_GET_START,
      payload: {
        reference
      }
    });
    api.get('/api/configuration/' + reference).then(configuration=>{
      // Check in case a used font is not available anymore but must de displayed
      checkLoadedFonts(getState().user,configuration);
        const configObj = configuration.configuration;
        localStorage.setItem('gpg-configuration-type', configObj.designType || "A");
        /* if (configuration.stats && !configuration.stats.session) {
          configuration.stats.session = getFamilySession();
        } */
        
        // const isFamilyConfig = window?.location?.href?.includes("famille");
        // if (configObj.monument.kind !== "ACC" && !isFamilyConfig) {
        //   // updateFrameAndVeneerScenes(configObj, configuration?.options?.layouts);
        // }
        let preIsChange = false;
        if (configObj.monument.kind === "PLT" && !configObj.veneerScenes) {
          updateFrameAndVeneerScenes(configObj, configuration?.options?.layouts);
          preIsChange = true;
        }

        initConfigDecoration(configuration, preIsChange).then((configAndOpt) => {
          const isoView = displayStandardConfigImage(configAndOpt.configuration);
          dispatch({
            type: types.CONFIGURATION_GET_SUCCESS,
            payload: {
              configuration: configAndOpt,
              isoView,
            }
          });

          if (window.location.href.includes("famille")) {
            const data = configAndOpt && prepareData(configAndOpt,getState().user);
            document.title = `Configurateur famille - ${data.name}`
            document.querySelector('meta[name="description"]').setAttribute("content", `Configurez votre monument funéraire ${data.name} unique et personnalisez-le. Profitez d'un large catalogue de monuments funéraires et cinéraires modernes et de qualité.`)
          }
        })
  })
}
}

export function adjustNameMonument(payload) {
  return (dispatch, getState) => {
    dispatch({
      type: types.ADJUST_NAME_MONUMENT,
      payload: payload
    });
  }
}

const initConfigDecoration = async (configuration, preIsChange = false) =>{
  let isChange= preIsChange
  const faces = configuration.options.faces.map(face=> face.piece.reference)
  configuration.configuration.accessories.map( accessory => {
    if(!accessory.index || accessory.index=="undefined"){
      accessory.index = accessory.id;
      isChange=true;
    }
    
    if(!accessory.granite || accessory.granite == ""){
      const face =configuration.options.faces.filter(face => face.piece != "undefined" && face.piece.reference.startsWith(accessory.reference) )
      accessory.granite = face.length > 0 && face[0].piece && face[0].piece ? face[0].piece.granitCode :null;
      isChange=true;
    }
  })
  configuration.configuration.patterns.map( pattern => {
    if( pattern.face && !pattern.face.index){
      pattern.face.index = null;
      isChange=true;
    }
    if(pattern.face && !pattern.face.granit){
      const face =configuration.options.faces.filter(face => face.piece != "undefined" &&  face.piece.reference == pattern.face.piece )
      pattern.face.granit = face.length > 0 && face[0].piece ? face[0].piece.granitCode : null;
      isChange=true;
    }
    if(!pattern.face || (pattern.face && (faces.includes(pattern.face.piece) || pattern.face.piece.includes("STELE")))) {
      const faceDefined = pattern.face ? faces.find(face=> face.includes("STELE") && face.includes(`-${pattern.face.piece.split("-")[1]}`)) : null
      if (faceDefined) {
        pattern.face.piece = faceDefined
      }
      isChange=true;
    }
  })
  configuration.configuration.engravings.map( engraving => {
    if(engraving.face && !engraving.face.index){
      engraving.face.index = null;
      isChange=true;
    }
    if(engraving.face && !engraving.face.granit){
      const face =configuration.options.faces.filter(face => face.piece != "undefined" && face.piece.reference == engraving.face.piece )
      engraving.face.granit = face.length > 0 && face[0].piece ? face[0].piece.granitCode :null;
      isChange=true;
    }
    if(!engraving.face || (engraving.face && (faces.includes(engraving.face.piece) || engraving.face.piece.includes("STELE")))) {
      const faceDefined = engraving.face ? faces.find(face=> face.includes("STELE") && face.includes(`-${engraving.face.piece.split("-")[1]}`)) : null
      const face =configuration.options.faces.filter(face => face.piece.reference === faceDefined)
      if (faceDefined) {
        engraving.face.piece = faceDefined
        engraving.face.granit = face.length > 0 && face[0].piece ? face[0].piece.granitCode : null
      }
      isChange=true;
    }
  })
  if (isChange && !isConfigurationRequested(configuration.configuration)) {
    const configAndOpt = await api.put(`/api/configuration/${configuration.configuration.reference}`, configuration.configuration)
    return configAndOpt
  }
  return configuration;
}

export function startGettingAccessories(from = "") {
  return (dispatch)=>{
    dispatch({type: types.CONFIGURATION_GET_ACCESSORIES_START, payload: { from }});
  }
}

export function   storeAccessories(accessories) {
  return (dispatch)=>{
    dispatch({type: types.CONFIGURATION_GET_ACCESSORIES_SUCCESS,payload:accessories});
  }
}

export function stopConfiguration() {
  return {
    type: types.CONFIGURATION_STOP
  };
}

export function createVariant(configuration, path, clientId = false, fromFamilyspace = false, projectName = false) {

  return (dispatch) => {
    api.post('/api/configuration', {configuration, fromFamilyspace, projectName})
      .then(result => {
        clientId ? history.push({pathname: `/famille/${clientId}/${result.reference}/${path}`}):
                   history.push({pathname: `/configuration/${result.reference}/${path}`});
          // Hide warning msg of isConfigRequested
          dispatch({ type: types.UI_REMOVE_MESSAGES, payload : { id: 'isConfigRequested' } });
          // 
          dispatch({
            type: types.UI_ADD_MESSAGE,
            payload: {
              id: 'variant-creation-ok',
              className: 'success',
              innerHTML: `
                    Vous êtes en cours d'édition d'une variante de
                    votre précédente configuration`
            }
          });
        }
      )
      .catch(error => {
        dispatch({
          type: types.UI_ADD_MESSAGE,
          payload: {
            id: 'variant-creation-failed',
            className: 'danger',
            innerHTML: `
                    Une erreur est survenue lors de la création de la variante.
                    Vous êtes toujours sue la configuration d'origine.`

          }
        });

      });
  }
};

export function filteredLayouts(needFilter) {
  return {
    type: types.CONFIGURATION_NEED_LAYOUT_FILTERING,
    payload:needFilter
  };
};

export function changeSelection(selectionList){
  return {
    type: types.CONFIG_LAST_SELECTION,
    payload:selectionList
  };
}

export function simulatePaymentSchedule(amountCents, currentDate) {
  return async (dispatch) => {
    api.get('/api/simulate/payment/schedule', {amountCents, currentDate}).then(amount => {
      dispatch({type: types.CONFIGURATION_GET_SIMULATE_PAYMENT_SCHEDULE, payload:amount});
    })
  }
}

export function getIntroJson(member_id) {
  return async (dispatch) => {
    api.get('/api/intro', {member_id}).then(data => {
      dispatch({type: types.CONFIGURATION_GET_INTRO, payload:data});
    })
  }
}

export function addReadOnboarding(member_id, onboarding_id) {
  return async (dispatch) => {
    api.post('/api/intro', {member_id, onboarding_id}).then(result => {
      if(result && result.affectedRows) {
        dispatch({type: types.CONFIGURATION_ADD_READ_ONBOARDING, payload:{member_id, onboarding_id}});
      }
    })
  }
}
