/**
 * Get validation rules for a specific frame kind
 * @param {string} kind - Frame kind (C, B, E, R)
 * @param {Array} validationRules - Array of validation rules
 * @returns {Object} Validation rules for the specified kind
 */
export const getValidationRules = (kind, validationRules) => {
    return validationRules.find(rule => rule.finition === kind) || null;
};

/**
 * Validate scenario based on allowed scenarios
 * @param {string} frontLayout - Front layout (parpaing/closoir)
 * @param {string} backLayout - Back layout (parpaing/closoir)
 * @param {Array} allowedScenarios - Array of allowed scenarios
 * @returns {boolean} Is scenario valid
 */
export const isValidScenario = (frontLayout, backLayout, allowedScenarios) => {
    const scenario = `${frontLayout === 'parpaing' ? 'P' : 'C'}${backLayout === 'parpaing' ? 'P' : 'C'}`;
    return allowedScenarios.includes(scenario);
};

/**
 * Check if view is editable based on allowed views
 * @param {string} view - View type (FV/FL)
 * @param {Array} allowedViews - Array of allowed views
 * @returns {boolean} Is view editable
 */
export const isViewEditable = (view, allowedViews) => {
    return allowedViews.includes(view);
};

/**
 * Get layout options for front and back configurations
 * @param {Array<string>} scenarios - Array of allowed scenarios (e.g., ['PP', 'PC', 'CP'])
 * @returns {Object} Object containing two functions:
 *   - getFrontLayoutsForBack: Function to get available front layouts based on back layout
 *   - getBackLayoutsForFront: Function to get available back layouts based on front layout
 */
export const getLayoutOptions = (scenarios) => {
    // Helper to get available front layouts based on selected back layout
    const getFrontLayoutsForBack = (backLayout) => {
        return scenarios
            .filter(scenario => scenario[1] === (backLayout === 'parpaing' ? 'P' : 'C'))
            .map(scenario => scenario[0] === 'P' ? 'parpaing' : 'closoir');
    };

    // Helper to get available back layouts based on selected front layout
    const getBackLayoutsForFront = (frontLayout) => {
        return scenarios
            .filter(scenario => scenario[0] === (frontLayout === 'parpaing' ? 'P' : 'C'))
            .map(scenario => scenario[1] === 'P' ? 'parpaing' : 'closoir');
    };

    return {
        getFrontLayoutsForBack,
        getBackLayoutsForFront,
    };
};

/**
 * Get height validation rules and constraints
 * @param {Object} rules - Validation rules object
 * @param {Array<number|Array<number>>} rules.epaisseursAutorisees - Allowed heights (either simple array or array of pairs)
 * @returns {Object} Height validation object containing:
 *   - simpleHeights: Boolean indicating if heights are simple values or pairs
 *   - allowedHeights: Array of allowed heights
 *   - minHeight: Minimum allowed height
 *   - maxHeight: Maximum allowed height
 */
export const getHeightValidation = (rules) => {
    if (!rules || !rules.epaisseursAutorisees) {
        return {
            simpleHeights: true,
            allowedHeights: [3, 4, 5, 6, 7, 8],
            minHeight: 3,
            maxHeight: 8
        };
    }

    const isSimpleHeights = !Array.isArray(rules.epaisseursAutorisees[0]);
    return {
        simpleHeights: isSimpleHeights,
        allowedHeights: rules.epaisseursAutorisees,
        minHeight: isSimpleHeights ?
            Math.min(...rules.epaisseursAutorisees) :
            Math.min(...rules.epaisseursAutorisees.map(pair => pair[0])),
        maxHeight: isSimpleHeights ?
            Math.max(...rules.epaisseursAutorisees) :
            Math.max(...rules.epaisseursAutorisees.map(pair => pair[0]))
    };
};

/**
 * Find matching bedding rule for a given value
 * @param {number} value - The value to validate against bedding rules
 * @param {Array<Object>} beddingRules - Array of bedding validation rules
 * @param {number|Array<number>} beddingRules[].vueEnCm - Single value or range [min, max] in centimeters
 * @returns {Object|undefined} Matching bedding rule or undefined if no match found
 */
export const getMatchedBeddingValidation = (value, beddingRules) => {
    return beddingRules.find(rule => {
        if (Array.isArray(rule.vueEnCm)) {
            // Handle range case [min, max]
            return value >= rule.vueEnCm[0] && value <= rule.vueEnCm[1];
        } else {
            // Handle exact value case
            return value === rule.vueEnCm;
        }
    });
}