import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as updateConfigurationActions from '../../../actions/updateConfigurationActions';
import * as _ from 'lodash';
import {OptionPanel} from '../components/OptionPanel';
import {InputNumber} from '../../../components/InputNumber';
import {formatFrame} from '../../../services/format.service';
import {isMonumentCineraire, replaceMultiplicationSymbol} from '../../../services/utils.service';
import cn from 'classnames';
import {Table} from 'reactstrap';
import { TranslationContext } from "../../../context/TranslationContext";
import { Modal } from 'reactstrap'
import { isSmartphone } from '../../../services/domFunctions';
import { getFrontDepth , getLeftWidth} from '../../../services/frame.service';
import LumiImagePreview from './LumiImagePreview';
import {
  getValidationRules,
  isValidScenario,
  isViewEditable,
  getLayoutOptions,
  getHeightValidation,
  getMatchedBeddingValidation
} from '../../helpers/frameHelper';

export const FrameOptionPanelId = "frameOptionPanel";

// const simpleHeightMinMax = [3, 8];
// const doubleHeightMinMax = [5, 10];
const MIN_BEDDING = 5;
const MAX_BEDDING = 20;
const frSides={front:"Avant",back:"Arrière",left:"Gauche",right:"Droit"}

// Minimum de vue avant / arrière
const minFrameWidth = 1;

class FrameOptionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.onChangeBedding = _.debounce(this.onChangeBedding.bind(this), 1000);
    this.onChangeDepth = _.debounce(this.onChangeDepth.bind(this), 800);
    this.onChangeFrontDepth = _.debounce(this.onChangeFrontDepth.bind(this), 800);
    this.onchangeLeftWidth = _.debounce(this.onchangeLeftWidth.bind(this), 800);
    this.onChangeHeight = _.debounce(this.onChangeHeight.bind(this), 800);
  }
  
  static contextType = TranslationContext;
  static propTypes = {
    frame: PropTypes.shape({
      mode: PropTypes.string.isRequired,
      reference: PropTypes.string,
      kind: PropTypes.string,
      width: PropTypes.number,
      depth: PropTypes.number,
      heights: PropTypes.arrayOf(PropTypes.number),
      frontDepth: PropTypes.number,
      leftWidth: PropTypes.number,
      layout: PropTypes.string,
      frontLayout: PropTypes.string,
    }),
    frames: PropTypes.arrayOf(PropTypes.shape({
      kind: PropTypes.string.isRequired,
      defaults: PropTypes.arrayOf(PropTypes.shape({
        kind: PropTypes.string.isRequired,
        reference: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        width: PropTypes.number.isRequired,
        depth: PropTypes.number.isRequired,
        heights: PropTypes.arrayOf(PropTypes.number).isRequired,
        frontDepth: PropTypes.number.isRequired,
        leftWidth: PropTypes.number.isRequired,
        layout: PropTypes.string.isRequired,
        frontLayout: PropTypes.string.isRequired,
      })).isRequired,
      heights: PropTypes.number.isRequired
    })).isRequired
  };
  state = {
    showDim:false, 
    modalIsOpen:false, 
    frame: null,
    valid: false,
    cameraView: "normal"
  }

  getFrameValidationRulesList() {
    const { parameters } = this.props;
    const validatorConfig = parameters.find(p => p.key === 'CONFIG3D_FRAME_VALIDATOR');
    return validatorConfig ? validatorConfig.value : [];
  }

  getDefaultFrameView() {
    const { parameters } = this.props;
    const defaultView = parameters.find(p => p.key === 'CONFIG3D_DEFAULT_FRAME_VIEW');
    return defaultView && parseInt(defaultView.value) !== NaN ? defaultView.value : 15;
  }

  getBeddingValidationRulesList() {
    const { parameters } = this.props;
    const validatorConfig = parameters.find(p => p.key === 'CONFIG3D_BEDDING_VALIDATOR');
    return validatorConfig ? validatorConfig.value : [];
  }

  onSelectKind = async (event) => {
    const defaultView = this.getDefaultFrameView()
    const kind = event.target.value,
      frame = this.state.frame;
    // Si aucun type n'est sélectionné, on supprime toute la semelle
    if (!kind) {
      // this.setState({frame: null, valid: true})
      this.close()
      return await this.removeFrame();
    }
    const layout = _.find(this.props.layouts, {reference: this.props.monument.layout});

    const kindGroup = _.find(this.props.frames, {kind}),
      firstDefault = kindGroup.defaults && kindGroup.defaults[0],
      defaultHeight = (firstDefault && firstDefault.heights) || [7, 5].slice(-kindGroup.heights);

    if (!kindGroup.defaults || !kindGroup.defaults.length) {
      let width = layout.size.width + defaultView * 2;
      let depth = layout.size.depth + defaultView * 2;
      if (isMonumentCineraire(this.props.monument)) {  
        width = layout.size.width + defaultView * 2;
        depth = layout.size.depth + defaultView * 2;
      }
      // Si pas de semelles pré-définies, on force le custom
      return this.updateFrame({
        kind,
        mode: 'custom',
        layout:"parpaing", //On met parpaing arrière par défaut en custom
        frontLayout: "parpaing",
        heights: defaultHeight,
        width: frame.width || width,
        depth: frame.depth || depth,
      });
    } else if (frame.mode === 'custom') {
      // Si l'on était en mode custom, on modifie le kind et les heights
      return this.updateFrame({
        kind,
        heights: defaultHeight,
        leftWidth: 0,
        layout:"parpaing", //On met parpaing arrière par défaut en custom
        frontLayout: "parpaing",
      });
    } else {
      const nearestFrame = this.getExactFrameBySize(frame, kindGroup.defaults, kindGroup.kind, defaultHeight);

      if(nearestFrame.mode === 'custom') {
        return this.updateFrame(nearestFrame);
      } else {
        return this.updateFrame({
          ..._.omit(nearestFrame, ['name']),
          mode: 'standard',
          kind,
        });
      }

    }

  };

  toggleMode = () => {
    const frame = this.state.frame;

    if (frame.mode === 'standard') {
      // Passage en mode custom
      return this.updateFrame({
        mode: 'custom',
        layout:"parpaing", //On met parpaing arrière par défaut en custom
        frontLayout: "parpaing",
      })
    }

    if (frame.mode === 'custom') {
      // Passage en mode standard, on écrase tout sauf le kind
      return this.setToStandard(frame.kind, null)
    }
  };

  setToStandard = (kind, reference) => {
    const kindGroup = _.find(this.props.frames, {kind});

    const defaultParams = _.find(kindGroup.defaults, {reference}) || this.getNearestFrameBySize(
      this.state.frame,
      kindGroup.defaults,
      {
        mode: 'custom',
      }
    );

    return this.updateFrame({
      mode: 'standard',
      ..._.omit(defaultParams, ['name']),
    });
  };

  /**
   * Recherche parmi les semelles par défaut, la semelle qui a les dimensions les plus proches de la semelle de référence
   * @param referenceFrame
   * @param frameGroup
   * @param fallbackFrame
   * @returns {*}
   */
  getNearestFrameBySize = (referenceFrame, frameGroup, fallbackFrame) => {
    // On teste sur la largeur et la profondeur
    const valuesToTest = ['width', 'depth'];

    return  (_.chain(frameGroup)
      .map((params) => ({
        params,
        distance: _.chain(valuesToTest)
          .map(property => Math.abs(_.get(params, property) - _.get(referenceFrame, property)))
          .sum()
          .value()
      }))
      .orderBy('distance')
      .head()
      .value() || { params: fallbackFrame })
      .params;
  };

  /**
   * Recherche parmi les semelles par défaut la semelle qui a les mêmes dimensions que la semelle de référence ou retourne une semelle custom
   * @param referenceFrame
   * @param frameGroup
   * @param targetKind
   * @param defaultHeight
   * @returns {*|{kind: *, mode: string, heights: *, width: (*|number), depth: (*|number)}}
   */
  getExactFrameBySize = (referenceFrame, frameGroup, targetKind, defaultHeight) => {
    const sameSizedFrame = frameGroup.find(frame => frame.width === referenceFrame.width && frame.depth === referenceFrame.depth) || frameGroup[0];
    return sameSizedFrame || {
      kind: targetKind,
      mode: 'custom',
      heights: defaultHeight,
      width: referenceFrame.width || 140,
      depth: referenceFrame.depth || 240,
    };
  };

  onSelectStandardReference = (event) => {
    this.setToStandard(this.state.frame.kind, event.target.value);
  };

  onChangeWidth = (width, beddingRules) => this.updateFrame({width}, false, beddingRules);
  onChangeDepth = (depth, beddingRules) => this.updateFrame({depth}, false, beddingRules);

  onChangeBedding(bedding) {
    if (bedding >= MIN_BEDDING && bedding <= MAX_BEDDING) {
      return this.updateFrame({bedding});
    }
  };

  validateBeddingBasedOnview = (viewValue, beddingRules) => {
    if (!beddingRules || !beddingRules.length) {
      return this.state.frame.bedding || MIN_BEDDING;
    }
  
    // Find the matching rule
    const matchingRule = getMatchedBeddingValidation(viewValue, beddingRules);
  
    if (!matchingRule) {
      return this.state.frame.bedding || MIN_BEDDING;
    }
  
    const currentBedding = this.state.frame.bedding || MIN_BEDDING;
  
    // If current bedding is within the allowed range, keep it
    if (currentBedding >= matchingRule.poseMin && currentBedding <= matchingRule.poseMax) {
      return currentBedding;
    }
  
    // Otherwise return the minimum allowed value
    return matchingRule.poseMin;
  };

  onChangeFrontDepth(frontDepth, beddingRules){
    const frontValue = frontDepth ? frontDepth : 0
    const vueEnCm = Math.min(frontValue, this.state.frame.leftWidth);
    const newBidding = this.validateBeddingBasedOnview(vueEnCm, beddingRules);
    this.updateFrame({frontDepth: frontValue, bedding: newBidding});
  };

  onchangeLeftWidth(leftWidth, beddingRules){
    const leftValue = leftWidth ? leftWidth : 0
    const vueEnCm = Math.min(this.state.frame.frontDepth, leftValue);
    const newBidding = this.validateBeddingBasedOnview(vueEnCm, beddingRules);
    this.updateFrame({leftWidth: leftValue, bedding: newBidding});
  };

  onChangeLayout = (event, rules) => {
    const newBackLayout = event.currentTarget.value;
    const allowedScenarios = rules ? rules.scenarioAutorises : ["PP", "PC", "CP", "CC"];
    const { getFrontLayoutsForBack } = getLayoutOptions(allowedScenarios);
    
    const availableFrontLayouts = getFrontLayoutsForBack(newBackLayout);
    
    if (!availableFrontLayouts.includes(this.state.frame.frontLayout)) {
      // Auto-select first available front layout
      this.updateFrame({
        layout: newBackLayout,
        frontLayout: availableFrontLayouts[0]
      });
    } else {
      this.updateFrame({ layout: newBackLayout });
    }
  };
  onChangeFrontLayout = (event, rules) => {
    const newFrontLayout = event.currentTarget.value;
    const allowedScenarios = rules ? rules.scenarioAutorises : ["PP", "PC", "CP", "CC"];
    const { getBackLayoutsForFront } = getLayoutOptions(allowedScenarios);
    
    const availableBackLayouts = getBackLayoutsForFront(newFrontLayout);
    
    if (!availableBackLayouts.includes(this.state.frame.layout)) {
      // Auto-select first available back layout
      this.updateFrame({
        frontLayout: newFrontLayout,
        layout: availableBackLayouts[0]
      });
    } else {
      this.updateFrame({ frontLayout: newFrontLayout });
    }
  };

  onChangeHeight(height, validation) {
    if (validation.simpleHeights) {
      // Simple height validation
      const validHeight = Math.min(
        validation.maxHeight,
        Math.max(validation.minHeight, height || validation.minHeight)
      );
  
      // Ensure height is in allowed values
      const nearestAllowedHeight = validation.allowedHeights.reduce((prev, curr) => 
        Math.abs(curr - validHeight) < Math.abs(prev - validHeight) ? curr : prev
      );
  
      this.updateFrame({heights: [nearestAllowedHeight]});
    } else {
      // Double height validation
      const validHeight = Math.min(
        validation.maxHeight,
        Math.max(validation.minHeight, height || validation.minHeight)
      );
      
      // Find matching or closest height pair
      const heightPair = validation.allowedHeights.reduce((prev, curr) => 
        Math.abs(curr[0] - validHeight) < Math.abs(prev[0] - validHeight) ? curr : prev
      );
  
      this.updateFrame({heights: heightPair});
    }
  };

  /**
   * Retourne la référence d'une frame custom.
   * @param configuration
   * @returns {string}
   */

  getCustomFrameReference = () => {
    return 'SEM';
  };

  close = () => {
    if (!isSmartphone()) {
      this.refs.Popup.onClose(); // appel de la fonction close de OptionPanel sur appui sur Fermer
    }
  };

  save=() => {
    this.updateFrame(this.state.frame, true)
    this.close()
  };

  getNewBedding = (frontDepth, leftWidth, beddingRules) => {
    const vueEnCm = Math.min(frontDepth, leftWidth);
    const newBidding = this.validateBeddingBasedOnview(vueEnCm, beddingRules);
    return newBidding;
  }

  updateFrame = (newValue, valid = false, beddingRules = null) => {
    const newFrame = Object.assign({}, this.state.frame, newValue);
    if (!valid) {
      if (newFrame.mode === 'custom') {
        const layout = _.find(this.props.layouts, {reference: this.props.monument.layout});
        newFrame.reference = this.getCustomFrameReference();

        // Check if first time change by user
        if (newValue.hasOwnProperty('frontDepth') && !isNaN(newValue.frontDepth)) {
          newFrame.initDepth = true;
        }
        if (newValue.hasOwnProperty('width')) {
          newFrame.leftWidth = 0
        } 
        if (newValue.hasOwnProperty('depth')) {
          newFrame.initDepth = false;
        }
        newFrame.frontDepth = getFrontDepth(newFrame, layout.size);
        newFrame.leftWidth = getLeftWidth(newFrame, layout.size);
        if (beddingRules) {
          newFrame.bedding = this.getNewBedding(newFrame.frontDepth, newFrame.leftWidth, beddingRules);
        }
        this.setState({frame:newFrame})
      } else {
        newFrame.initDepth = false;
      }
    }

    if ((newFrame.mode === 'custom' && valid) || newFrame.mode !== 'custom') {
      newFrame.details = newFrame.width + " x " + newFrame.depth + " x " + newFrame.heights.join("/")
      this.setState({frame:newFrame, modalIsOpen: false})
      this.props.actions.updateFrame(newFrame);
    }
  };

  removeFrame = async() => {
    
    await this.props.actions.removeFrame();
    this.setState({valid: true})
  }
  defaultFrontDepth = () => (this.state.frame.depth - this.getMonumentLayoutDepth()  ) / 2;
  getMonumentLayoutDepth = () => _.find(this.props.layouts, {reference: this.props.monument.layout}).size.depth;

  defaultLeftWidth = () => (this.state.frame.width - this.getMonumentLayoutWidth()  ) / 2;
  getMonumentLayoutWidth = () => _.find(this.props.layouts, {reference: this.props.monument.layout}).size.width;

  openModal = () => {
    this.setState({modalIsOpen:true})
  }

  handleCameraViewChange = (newCameraView) => {
    this.setState({ cameraView: newCameraView });
  };

  componentDidUpdate(prevProps, prevState) {
    const frames = this.props.frames;
    const frame = this.props.frame;
    const kind = frame.kind;
    const kindGroup = _.find(frames, {kind});

    if (this.state.frame === null || this.state.valid){
      this.setState({frame:this.props.frame, valid: false})
    }

    if (this.props.action === "CONFIG_UPDATE_SUCCESS"  && this.state.frame && 
        (this.state.frame.reference !== this.props.frame.reference || 
          this.state.frame.leftWidth !== this.props.frame.leftWidth || 
          this.state.frame.frontDepth !== this.props.frame.frontDepth ||
          this.state.frame.width !== this.props.frame.width ||
          this.state.frame.depth !== this.props.frame.depth) ) {
        this.props.frame.details = this.props.frame.width + " x " + this.props.frame.depth + " x " + this.props.frame.heights.join("/")
        this.setState({ frame: this.props.frame}, () =>{})
    }

    if (kindGroup && kindGroup.defaults && kindGroup.defaults.length === 0 && this.state.frame && this.state.frame?.mode !== "custom") {
      this.setState({frame: {...this.state.frame, mode:"custom"}})
    }
  }

  render() {
    const t = this.context;
    const monumentComponents = ["SEM","PLT","ACC"];
    const isMonumentComponent = monumentComponents.includes(this.props.monument.category)
    let frame = this.props.frame;
    if(this.state.frame) {
      frame = this.state.frame
    }
    const mode = frame.mode,
    kind = mode && frame.kind,
    frames = this.props.frames,
    kindGroup = _.find(frames, {kind}),
    layout = _.find(this.props.layouts, {reference: this.props.monument.layout}),
    frontDepth = getFrontDepth(frame, layout.size), 
    leftWidth =  getLeftWidth(frame,layout.size)
    
    const sizes = frame.details ? frame.details.replace(/\s/g, '') : "";
    const displayValue = replaceMultiplicationSymbol(frame.depth && frame.depth !==0 ? sizes:"NON");
    const disabledFrame=this.props.frameEnabled !== undefined && !this.props.frameEnabled
    const frontLayout = frame.frontLayout ? frame.frontLayout : "parpaing"
    const buttonContent = t("config3d_stepper_frame") || "Semelle";
    const isMobile = isSmartphone();
    const FrameOptionContent = () => {
      // Get validation rules for current frame kind
      const rules = getValidationRules(kind, this.getFrameValidationRulesList());
      const beddingRules = this.getBeddingValidationRulesList();
      const vueEnCm = Math.min(frontDepth, leftWidth);
      const matchingRule = getMatchedBeddingValidation(vueEnCm, beddingRules);
      // Determine which views are editable
      const isFrontViewEditable = rules ? isViewEditable("FV", rules.vuesAutorisees) : true;
      const isLeftViewEditable = rules ? isViewEditable("FL", rules.vuesAutorisees) : true;
      
      // Get min/max values for dimensions
      const dimensionLimits = rules ? {
        minLength: rules.longueurMin,
        maxLength: rules.longueurMax,
        minView: rules.vueMin
      } : {
        minLength: 40,
        maxLength: 460,
        minView: 0
      };

      const validation = getHeightValidation(rules);

      // Get allowed scenarios for layouts
      const allowedScenarios = rules ? rules.scenarioAutorises : ["PP", "PC", "CP", "CC"];
      const { getFrontLayoutsForBack, getBackLayoutsForFront } = getLayoutOptions(allowedScenarios);

      // Get available layouts based on current selections
      const frontLayouts = frame.layout ? 
        getFrontLayoutsForBack(frame.layout) : 
        allowedScenarios.map(s => s[0] === 'P' ? 'parpaing' : 'closoir').filter((v, i, a) => a.indexOf(v) === i);

      const backLayouts = frame.frontLayout ? 
        getBackLayoutsForFront(frame.frontLayout) : 
        allowedScenarios.map(s => s[1] === 'P' ? 'parpaing' : 'closoir').filter((v, i, a) => a.indexOf(v) === i);
      
        // Validate current layout combination
      const isValidLayout = !rules || isValidScenario(
        frame.frontLayout, 
        frame.layout,
        allowedScenarios
      );

      return (
        <div>
          <div style={{ 
            display: 'flex', 
            flexDirection: isMobile ? 'column' : 'row',
            gap: '20px'
          }}>
            <div style={{
              flex: 1,
              backgroundColor: '#fff',
              padding: '0px 20px',
              borderRadius: '8px',
            }}>
              {isMobile && (
                <div className='header modal-header'>
                  <h2 className='modal-title'>{this.props.title}</h2>
                  <button
                    type='button'
                    className='close'
                    aria-label='Close'
                    onClick={() => this.setState({modalIsOpen:!this.state.modalIsOpen})}
                  >
                    <i className='icon material-icons'></i>
                  </button>
                </div>
              )}
              <label className="title">
                { t("config3d_monument_modal_frame_type") || "Type de semelle" }
              </label>
              <div>
                <select value={kind} onChange={this.onSelectKind}>
                  {this.props.monument.category !== "SEM" && <option value="">
                    { t("config3d_monument_modal_frame_list_noframe") || "Sans semelle" }
                  </option>}
                  {frames.map(frame => (
                    <option key={frame.kind} value={frame.kind}>Semelle {frame.label}</option>
                  ))}
                </select>
              </div>
              {mode && (
                <div>
                  <div>
                    <label className="checkbox">
                      <input type="checkbox" className="custom-checkbox" id="checkbox_custom"
                        checked={mode === 'custom'}
                        disabled={!kindGroup?.defaults || !kindGroup?.defaults?.length}
                        onChange={this.toggleMode}
                      />
                      <label htmlFor="checkbox_custom">
                        <span>
                          { t("config3d_monument_modal_frame_checkbox_customsize") || "Dimensions sur-mesure" }
                        </span>
                      </label>
                    </label>
                  </div>

                  {mode === 'standard' && (
                    <>
                    <label className="title">Dimensions</label>
                    <div>
                      <select value={frame.reference} onChange={this.onSelectStandardReference}>
                        {kindGroup.defaults.map(std => (
                          <option key={std.reference} value={std.reference}>{formatFrame(std)}</option>
                        ))}
                      </select>
                    </div>
                    </>
                  )}
                  {mode === 'custom' && (
                    <>
                      <div style={{display: isMobile ? "unset" : "inline-flex"}}>
                        <div style={{marginTop:"-10px"}}>
                          <div style={{ display:"flex", gap:"16px" }}>
                            <div className="CustomProperty">
                              <label className="Label">
                                { t("config3d_monument_modal_frame_custom_front") || "Façade" }
                              </label>
                              <div className="input-wrapper">
                                <InputNumber 
                                  className="custom-input-field" 
                                  value={frame.width} 
                                  min={dimensionLimits.minLength} 
                                  max={dimensionLimits.maxLength}
                                  onValueChange={(value) => this.onChangeWidth(value, beddingRules)} />
                                <span className="custom-input-addon">
                                  { t("config3d_monument_modal_frame_measurementunit") || "cm" }
                                </span>
                              </div>
                            </div>
                            <div className="CustomProperty">
                              <label className="Label">
                                { t("config3d_monument_modal_frame_custom_depth") || "Profondeur" }
                              </label>
                              <div className="input-wrapper">
                                <InputNumber 
                                  className="custom-input-field" 
                                  value={frame.depth} 
                                  min={dimensionLimits.minLength} 
                                  max={dimensionLimits.maxLength}
                                  onValueChange={(value) => this.onChangeDepth(value, beddingRules)}/>
                                <span className="custom-input-addon">
                                  { t("config3d_monument_modal_frame_measurementunit") || "cm" }
                                </span>
                              </div>
                            </div>
                            <div className={`CustomProperty Heights ${kind === "R" || kind === "E" ? "double" : ""}`}>
                              <label className="Label">
                                { t("config3d_monument_modal_frame_custom_height") || "Hauteur" }
                              </label>
                              <div className="input-wrapper">
                                {validation.simpleHeights ? (
                                  // Single height input
                                  <InputNumber 
                                    className="custom-input-field" 
                                    value={frame?.heights?.[0]} 
                                    min={validation.minHeight}
                                    max={validation.maxHeight}
                                    step={1} 
                                    onValueChange={(value) => this.onChangeHeight(value, validation)}
                                  />
                                ) : (
                                  // Double height inputs
                                  <>
                                    <InputNumber 
                                      className="custom-input-field" 
                                      key="height-0" 
                                      value={frame?.heights?.[0]} 
                                      min={validation.minHeight}
                                      max={validation.maxHeight}
                                      step={1} 
                                      onValueChange={(value) => this.onChangeHeight(value, validation)}
                                    />
                                    <span className="Unit">/</span>
                                    <InputNumber 
                                      className="custom-input-field disabled" 
                                      value={frame.heights[1]} 
                                      disabled={true} 
                                    />
                                  </>
                                )}
                                <span className="custom-input-addon">
                                  { t("config3d_monument_modal_frame_measurementunit") || "cm" }
                                </span>
                              </div>
                            </div>
                          </div>

                          <div style={{ display:"flex", gap:"16px" }}>
                            <div className="CustomProperty">
                              <label className="Label">
                              { t("config3d_monument_modal_frame_custom_frontoffset") || "Vue avant" }
                              </label>
                              <div className="input-wrapper">
                                <InputNumber 
                                  className="custom-input-field custom-input-field2" 
                                  value={frontDepth} 
                                  min={isFrontViewEditable ? dimensionLimits.minView : minFrameWidth} 
                                  max={frame.depth - layout.size.depth}
                                  disabled={!isFrontViewEditable}
                                  onValueChange={value => this.onChangeFrontDepth(value, beddingRules)} />
                                <span className="custom-input-addon">
                                  { t("config3d_monument_modal_frame_measurementunit") || "cm" }
                                </span>
                              </div>
                            </div>
                            <div className="CustomProperty">
                              <label className="Label">
                                { t("config3d_monument_modal_frame_custom_backoffset") || "Vue arrière" }
                              </label>
                              <div className="input-wrapper">
                                <InputNumber 
                                  className="custom-input-field custom-input-field2 disabled" 
                                  min={minFrameWidth} 
                                  value={frame.depth - layout.size.depth - frontDepth} 
                                  disabled={true} />
                                <span className="custom-input-addon">
                                  { t("config3d_monument_modal_frame_measurementunit") || "cm" }
                                </span>
                              </div>
                            </div>
                          </div>

                          <div style={{ display:"flex", gap:"16px" }}>
                            <div className="CustomProperty">
                              <label className="Label">
                              { t("config3d_monument_modal_frame_custom_leftoffset") || "Vue de gauche" }
                              </label>
                              <div className="input-wrapper">
                                <InputNumber 
                                  className="custom-input-field custom-input-field2" 
                                  value={leftWidth} 
                                  min={isLeftViewEditable ? dimensionLimits.minView : minFrameWidth} 
                                  disabled={!isLeftViewEditable}
                                  max={frame.width - layout.size.width}
                                  onValueChange={value => this.onchangeLeftWidth(value, beddingRules)}/>
                                <span className="custom-input-addon">
                                  { t("config3d_monument_modal_frame_measurementunit") || "cm" }
                                </span>
                              </div>
                            </div>
                            <div className="CustomProperty">
                              <label className="Label">
                                { t("config3d_monument_modal_frame_custom_rightoffset") || "Vue de droite" }
                              </label>
                              <div className="input-wrapper">
                                <InputNumber 
                                  className="custom-input-field custom-input-field2 disabled" 
                                  value={frame.width - (layout.size.width + leftWidth)}
                                  min={minFrameWidth}
                                  disabled={true} />
                                <span className="custom-input-addon">
                                  { t("config3d_monument_modal_frame_measurementunit") || "cm" }
                                </span>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="CustomProperty">
                              <label className="Label">
                                { t("config3d_monument_modal_frame_custom_pavingbed") || "Lit de pose" }
                              </label>
                              <div className="input-wrapper">
                                <InputNumber 
                                  className="custom-input-field" 
                                  value={frame.bedding} 
                                  min={matchingRule.poseMin || MIN_BEDDING} 
                                  max={matchingRule.poseMax || MAX_BEDDING}
                                  onFocus={event => event.target.select()}
                                  onValueChange={value => this.onChangeBedding(value)}
                                  />
                                <span className="custom-input-addon">
                                  { t("config3d_monument_modal_frame_measurementunit") || "cm" }
                                </span>
                              </div>
                            </div>
                          </div>

                          <label className="title">{ t("config3d_monument_modal_frame_footer_front") || "Pièce Avant" }</label>
                          <div className="CustomProperty" style={{ display: "flex", gap: "1rem", marginBottom: "0" }}>
                            {frontLayouts.map(layoutType => (
                              <label className="radio" key={`front-${layoutType}`}>
                                <input 
                                  type="radio" 
                                  name="frontlayout" 
                                  className="radio-input" 
                                  value={layoutType}
                                  id={`front-${layoutType}`}
                                  checked={frontLayout === layoutType}
                                  onChange={(event) => this.onChangeFrontLayout(event, rules)}
                                />
                                <label className="radio-label" htmlFor={`front-${layoutType}`}>
                                  <span className="radio-border"></span>
                                  {layoutType === 'parpaing' ? 
                                    t("config3d_monument_modal_frame_custom_front_breezeblock") || "Parpaing" :
                                    t("config3d_monument_modal_frame_custom_front_closure") || "Closoir"
                                  }
                                </label>
                              </label>
                            ))}
                          </div>

                          <label className="title">{ t("config3d_monument_modal_frame_footer_back") || "Pièce Arrière" }</label>
                          <div className="CustomProperty" style={{ display: "flex", gap: "1rem", marginBottom: "0" }}>
                            {backLayouts.map(layoutType => (
                              <label className="radio" key={`back-${layoutType}`}>
                                <input 
                                  type="radio" 
                                  name="layout" 
                                  className="radio-input"
                                  id={`back-${layoutType}`}
                                  value={layoutType}
                                  checked={frame.layout === layoutType}
                                  onChange={(event) => this.onChangeLayout(event, rules)}
                                />
                                <label className="radio-label" htmlFor={`back-${layoutType}`}>
                                  <span className="radio-border"></span>
                                  {layoutType === 'parpaing' ? 
                                    t("config3d_monument_modal_frame_custom_back_breezeblock") || "Parpaing" :
                                    t("config3d_monument_modal_frame_custom_back_closure") || "Closoir"
                                  }
                                </label>
                              </label>
                            ))}
                          </div>
                        </div>
                        <div className={isMobile ? "" : "frameImage"}>
                          <LumiImagePreview 
                            type="frame"
                            showSelect={true}
                            frameData={frame} 
                            containerStyle={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }} 
                            imageStyle={{marginTop: "3%", objectFit: "contain", width: "100%"}} 
                            selectStyle={{ width: isMobile ? "50%" : "30%", ...(isMobile && { height: "3rem" }) }}
                            cameraView={this.state.cameraView}
                            onCameraViewChange={this.handleCameraViewChange}
                          />
                        </div>
                      </div>
                      <div style={isMobile ? {} : {marginTop: '-16px'}}>
                        <button 
                          className={"button-primary text-cta button-medium"}
                          style={{float: "right", marginBottom: "5px", position: "relative", zIndex: 99}}
                          onClick={this.save}
                          disabled={!isValidLayout}
                        >
                          <span>{ t("config3d_monument_modal_veneer_validatebutton") || "Valider"}</span>
                        </button>
                      </div>
                    </>
              )}

                    {this.state.showDim && frame.dimensions && <div>
                      <Table striped bordered className="TableFramePieces">
                      <thead><tr><th>Pièce</th><th>Largeur</th><th>Profondeur</th><th>Hauteur</th></tr></thead>
                      <tbody>
                        {["front", "back", "left", "right"].map(side=>
                        <tr><td>{frSides[side]}</td><td>{frame.dimensions[side].width}</td><td>{frame.dimensions[side].depth}</td><td>{frame.heights[0]}</td></tr>
                        )}
                        </tbody>
                      </Table>
                    </div>
                  }  
                </div>
              )}
            </div>
          </div>
        </div>
      );
    }
      

    return (
      <OptionPanel
        id={FrameOptionPanelId}
        className={cn('FrameOptionPanel ' + mode, {mode})}
        buttonClassName={`btn-config-secondary text-dark config-button-background	 ${!disabledFrame ? 'config-button-hover' : ''}`}
        buttonContent={buttonContent}
        buttonIcon="frame-icon"
        title={disabledFrame && "Option non disponible pour ce monument"}
        hasContent={!!kind}
        showValue={displayValue}
        disabled={disabledFrame}
        isMonComponent={isMonumentComponent}
        ref= "Popup"
        isFrame={true}
        openModal={this.openModal}
      >

      {isMobile ? 
        <Modal
          isOpen={this.state.modalIsOpen}
          className='ModalFrameOptionPanel'
          centered={true}
          ref= "Popup"
          fade={true}
        >
          <FrameOptionContent />
        </Modal> : 
        <FrameOptionContent />
      }
      </OptionPanel>
    )
  }
}

export const FrameOptionPanel = connect((state, ownProps) => ({
    monument: state.configurator.current.configuration.monument,
    frame: state.configurator.current.configuration.frame,
    frames: state.configurator.current.options.frames,
    layouts: state.configurator.current.options.layouts,
    frameEnabled:state.configurator.current.options.functionalities.frameEnabled,
    parameters: state.configurator.parameters,
    action: state.lastAction
  }),
  (dispatch) => ({
    actions: bindActionCreators(updateConfigurationActions, dispatch)
  })
)(FrameOptionPanelComponent);

