import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {onClickOutsideOf} from '../../../services/domFunctions';
import {shouldFocus} from '../../../services/goToAndFocusItem.service';
import {clickTheCanvas} from '../../helpers/config3dHelper';

export class OptionPanel extends Component {
  static propTypes = {
    className: PropTypes.string,
    hasContent: PropTypes.bool,
    buttonContent: PropTypes.any.isRequired,
    buttonIcon: PropTypes.any,
    children: PropTypes.any.isRequired,
    disabled: PropTypes.bool,
    closeOnClickInside: PropTypes.bool,
    id: PropTypes.string,
    canClose: PropTypes.func,
    addClassName: PropTypes.string
  };

  state = {
    open: false
  };

  componentDidMount() {
    // Permet de déclencher l'ouverture d'un panel depuis l'onglet finalisation
    if (shouldFocus(this.props.id)) {
      setTimeout(() => this.onOpen());
    }
  }

  onToggle = () => {
    if (this.state.open) {
      this.onClose();
    } else {



      this.onOpen();
    }
  };






  onOpen = () => {
    this.setState({ open: true});
    if (this.props.informState){
      this.props.informState(true);
    }
    };
  onClose = () => {
  this.setState({open: false});
  if (this.props.informState){
    this.props.informState(false);
  }
  };

  clearListener;
  listenForOutsideClicks = (ref) => {
    if (ref && !this.clearListener) {
      // On ignore par défaut les clics venant de l'ensemble du composant
      let ignoreClicksFrom = ref;
      // Pour les panels qui se ferment quand on fait un choix (recette, soubassement) on
      // ignore uniquement les clics venant du bouton de toggle (ceux ci sont gérés séparement).
      if (this.props.closeOnClickInside) {
        ignoreClicksFrom = ref.children[0];
      }

      this.clearListener = onClickOutsideOf(ignoreClicksFrom, this.onClose);
    }
  };

  componentWillUnmount() {
    this.clearListener()
  }

  render() {
    const { buttonIcon, title = "" } = this.props;
    return (
      <div ref={(ref) => this.listenForOutsideClicks(ref)} className={'OptionPanel ' + (this.props.className || '') + (this.props.hasContent ? ' hasContent' : '')}>

        <button className={cn(`SquareButton ${this.props.buttonClassName ? this.props.buttonClassName : ''}`, {
          active: this.state.open,
          disabled: this.props.disabled,
          hasContent: this.props.hasContent
        })}
          onClick={() => {
            this.onToggle();
            this.props.openModal && this.props.openModal();
            clickTheCanvas();
          }} 
          disabled={this.props.disabled}>
          <span 
            className={`${buttonIcon} icon`} 
            title={title}>
          </span>
         <div>{this.props.buttonContent}</div> 
        </button>
        {this.props.showValue  && !this.props.fctValue  &&(<div className='ShowValue text-dark'>{this.props.showValue}</div>)}
        {this.props.showValue  && this.props.fctValue  &&(<div className='ShowValue text-dark'>
        {/* <button className="ButtonValue"  onClick={this.props.fctValue} title ={this.props.btnValueTitle}>
        {this.props.showValue}
        </button> */}
        </div>)}

        {!this.props.disabled && (<div className={cn(`OptionPanelPopup background-page text-dark text-medium ${this.props.isMonComponent ? 'buttonBefore' : ''}`, {show: this.state.open },this.props.addClassName)} 
          // style={this.props.isMonComponent ? this.props.isFrame? { top: '130px'} : this.props.isLayout? { top: '80px'}: { top: '80px'} : {}}
        >
          {this.props.children}
        </div>)}
      </div>
    )
  }
}
