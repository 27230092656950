import { semelleAssemblyFrontViewOffset, semelleAssemblyHeight, semelleAssemblyLeftViewOffset, semelleAssemblyScene, placageAssemblyScene, HRAabb3, aabbPointOfView, aabbFacePointOfView, FovType, Face } from "@lumiscaphe/gpg-webrender-tools"
import { getFrontDepth } from "./frame.service";
import { post } from './api.service';

// build and transform frame scenes

// TODO: in the case of the Dimensions sur-mesure, the frontView is comming weird, i must verify the frontView calculation
export const buildAndTransformFrameScenes = (configuration, selectedLayout) => {
  const frame = configuration.frame;
  const transDime = transformFrameParts(configuration.frame.dimensions)
  // swap array item if 2 items
  const height = frame.heights.length === 1 ? frame.heights[0] : [frame.heights[1], frame.heights[0]]
  const frameData = {
    database: "63a558ca-171c-4240-8a59-7c2e050b538c",
    assemblyType: getAssemblyType(frame.layout, frame.frontLayout) || "PP",
    bedding: frame.bedding,
    height: height,
    parts: transDime,
    hammered: ["B", "E"].includes(frame.kind) ? true : false,
    frontView: getFrontDepth(frame, selectedLayout.size),
    overall: {
      width: frame.width,
      depth: frame.depth
    },
    leftView: frame?.leftWidth || ((frame.width - selectedLayout.size.width) / 2),
    recette: {
      width: selectedLayout.size.width,
      depth: selectedLayout.size.depth
    }
  }

  // console.log("frameData", frameData);

  const transformedData = transformFrameScenes(frameData, configuration);

  if (selectedLayout?.reference === "A" && frame.kind === "C" && frame.reference === "AS140240C05") {
    transformedData.frameScenes = [];
  }

  return transformedData;
}

export const buildAndTransformVeneerScenes = (configuration, transPoints, frame, selectedLayout, defaultVeneerData = null) => {
  const veneer = configuration.veneer;
  if (!veneer?.layout) return [];

  const transformedParts = transformVeneerParts(veneer);
  const veneerData = {
    database: "1309d3af-deb0-4b1c-9a1f-80dcfb42785c",
    assemblyType: veneer.assemblyType || "PP",
    thickness: getVeneerThickness(veneer.dimensions),
    parts: transformedParts,
    overall: getVeneerOverall(veneer, frame, selectedLayout, defaultVeneerData),
  }

  // console.log("veneerData", veneerData);

  return transformVeneerScenes(veneerData, configuration, transPoints);
}

const transformFrameScenes = (frameData, configuration) => {
  const semelleTranslationX = semelleAssemblyLeftViewOffset(frameData);
  const semelleTranslationY = -semelleAssemblyHeight(frameData) - 0.001;
  const semelleTranslationZ = -semelleAssemblyFrontViewOffset(frameData);

  const graniteMain = configuration.monument.graniteMain;
  const frameScenes = semelleAssemblyScene(frameData).map(p => {
    return ({
      ...p,
      materials: p.materials ? p.materials.map((m) => ({ ...m, filename: "Granites\\" + graniteMain })) : [],
      translation: {
        x: (p.translation?.x ?? 0) + semelleTranslationX,
        y: (p.translation?.y ?? 0) + semelleTranslationY,
        z: (p.translation?.z ?? 0) + semelleTranslationZ
      },
      visible: true
    })
  });

  return {
    frameScenes,
    transPoints: {
      semelleTranslationX,
      semelleTranslationY,
      semelleTranslationZ
    }
  };
}

const transformVeneerScenes = (veneerData, configuration, transPoints) => {
  const { semelleTranslationX, semelleTranslationY, semelleTranslationZ } = transPoints;

  const placageTranslationX = semelleTranslationX || 0;
  const placageTranslationY = semelleTranslationY != 0 ? (semelleTranslationY - 0.001) : 0;
  const placageTranslationZ = semelleTranslationZ || 0;

  const graniteMain = configuration.monument.graniteMain;

  const veneerScenes = placageAssemblyScene(veneerData).map(p => {
    return ({
      ...p,
      materials: p.materials ? p.materials.map((m) => ({ ...m, filename: "Granites\\" + graniteMain })) : [],
      translation: {
        x: (p.translation?.x ?? 0) + placageTranslationX,
        y: (p.translation?.y ?? 0) + placageTranslationY,
        z: (p.translation?.z ?? 0) + placageTranslationZ
      },
      visible: true
    })
  });

  return veneerScenes;
}

export const getVeneerThickness = (dimensions) => {
  let thickness = 0;
  ['front', 'back', 'left', 'right'].forEach(side => {
    if (dimensions[side] && dimensions[side].thickness) {
      // return if is number
      if (!isNaN(dimensions[side].thickness)) {
        thickness = dimensions[side].thickness;
        return;
      }
    }
  });

  return thickness;
}

export const getVeneerDimensions = (dimensions) => {
  let  newdimensions= {back: {
    checked: true,
    left: 0,
    right: 0,
    length: 0
  },
  front: {
    checked: true,
    left: 0,
    right: 0,
    length: 0
  },
  right: {
    checked: true,
    front: 0,
    back: 0,
    length: 0
  },
  left: {
    checked: true,
    front: 0,
    back: 0,
    length: 0
  }}
  const sides = ['front', 'back', 'left', 'right']
  sides.forEach(side => {
      // // return if is number
    if (dimensions[side] && dimensions[side].length && ["front", "back"].includes(side)) {
      newdimensions[side] = {
        checked: true,
        left: dimensions[side].left,
        right: dimensions[side].right,
        length: dimensions[side].length
      }
    } else if (dimensions[side] && dimensions[side].length && ["left", "right"].includes(side)) {
      newdimensions[side] = {
        checked: true,
        front: dimensions[side].front,
        back: dimensions[side].back,
        length: dimensions[side].length
      }
    } else {
      newdimensions[side].checked = false
    }
    
  });

  return newdimensions;
}


export const getVeneerHeight = (dimensions) => {
  let height = 0;
  ['front', 'back', 'left', 'right'].forEach(side => {

    if (dimensions[side] && ['front', 'back'].includes(side)) {
      if (dimensions[side].right && !isNaN(dimensions[side].right)) {
        height = dimensions[side].right;
        return;
      } else if (dimensions[side].left && !isNaN(dimensions[side].left)) {
        height = dimensions[side].left;
        return;
      }
    } else if (dimensions[side] && ['left', 'right'].includes(side)) {
      if (dimensions[side].front && !isNaN(dimensions[side].front)) {
        height = dimensions[side].front;
        return;
      } else if (dimensions[side].back && !isNaN(dimensions[side].back)) {
        height = dimensions[side].back;
        return;
      } 
      
    }
    
  });

  return height;
}

const getHeightbasedOnSide = (side, dimensions) => {
  switch (side) {
    case 'front':
      return [dimensions[side].left || 0, dimensions[side].right || 0];
    case 'back':
      return [dimensions[side].right || 0, dimensions[side].left || 0];
    case 'left':
      return [dimensions[side].back || 0, dimensions[side].front || 0];
    case 'right':
      return [dimensions[side].front || 0, dimensions[side].back || 0];
    default:
      return 0;
  }
}

const transformVeneerParts = (veneer) => {
  const dimensions = veneer?.dimensions || {};
  const final = {};

  // List of all possible sides
  const sides = ['front', 'back', 'left', 'right'];

  // Process each side only if it exists in dimensions
  sides.forEach(side => {
    if (dimensions[side]) {
      final[side] = {
        width: dimensions[side].length || 0,
        height: getHeightbasedOnSide(side, dimensions),
      };
    }
  });

  return final;
}

const transformFrameParts = (dimensions) => {
  const leftPart = dimensions.left;
  const rightPart = dimensions.right;
  const newPart = {
    width: leftPart.width,
    depth: leftPart.depth,
  }

  const newRightPart = {
    width: rightPart.width,
    depth: rightPart.depth,
  }

  return {
    ...dimensions,
    left: newPart,
    right: newRightPart,
  }
}

export const getVeneerOverall = (veneer, frame, selectedLayout, defaultVeneerData = null) => {
  if (veneer.width && veneer.length) {
    return {
      width: veneer.width,
      depth: veneer.length,
    }
  }

  // get the overall dimensions from veneer details string ex ("Dimensions hors tout : 238 cm  x 148 cm")
  if (veneer.details) {
    const details = veneer.details;
    const dimensions = details.split("Dimensions hors tout : ")[1]?.replace("  ", " ")?.split("cm x ");
    return {
      width: Number(dimensions[1].split("cm")[0]?.replace(" ", "")),
      depth: Number(dimensions[0]?.replace(" ", "")),
    }
  }

  if (frame?.width && frame?.depth) {
    return {
      width: frame.width,
      depth: frame.depth,
    }
  }
  
  if (selectedLayout?.size.width && selectedLayout?.size.depth) {
    return {
      width: selectedLayout.size.width,
      depth: selectedLayout.size.depth,
    }
  }

  if (defaultVeneerData) {
    return {
      width: defaultVeneerData.width,
      depth: defaultVeneerData.depth,
    }
  }

  return {
    width: 0,
    depth: 0,
  }
}

export const veneerAssemblyType = (layout) => {
  const layouts = layout.toLowerCase().split("/")
  const backLayout = layouts.find(l=> l.includes(" ar"))?.trim().split(" ")[0]
  const frontLayout = layouts.find(l=> l.includes(" av"))?.trim().split(" ")[0]
  return getAssemblyType(backLayout, frontLayout)
}

const getAssemblyType = (layout, frontLayout = "parpaing") => {
  const layoutMap = {
    "parpaing": "P",
    "closoir": "C",
  }

  return `${layoutMap[frontLayout]}${layoutMap[layout]}`;
}

/**
 * Get camera parameters for the given scenes
 * @param {Object[]} scenes - Array of scene objects to calculate camera parameters for
 * @returns {Promise<Object>} Camera parameters including normal and up views
 */
export const getCameraParams = async (scenes) => {
  const aabbObj = await getAabb(scenes);

  return getCameraPov(aabbObj);
}

/**
 * Calculate camera point of view parameters based on AABB data
 * @param {Object} aabbObj - Axis-Aligned Bounding Box object containing min/max coordinates
 * @param {Object} aabbObj.min - Minimum coordinates {x,y,z}
 * @param {Object} aabbObj.max - Maximum coordinates {x,y,z}
 * @returns {Object|null} Camera POV parameters with normal and up views, or null if invalid input
 */
const getCameraPov = aabbObj => {
  if (!aabbObj || !aabbObj.min || !aabbObj.max) {
    return null;
  }

  const pMin = [aabbObj.min.x, aabbObj.min.y, aabbObj.min.z];
  const pMax = [aabbObj.max.x, aabbObj.max.y, aabbObj.max.z];
  const aabbInstance = HRAabb3.initPoints(pMin, pMax);

  const pointOfView = (aabb) => {
    const aabbForPov = HRAabb3.initPoints(aabb.pointMin, aabb.pointMax);

    aabbForPov.pointMin = aabb.pointMin.map(c => c * 1.1);
    aabbForPov.pointMax = aabb.pointMax.map(c => c * 1.1);
    aabbForPov.pointMax[1] = Math.max(aabb.pointMax[1], 0.75);

    return aabbPointOfView(aabbForPov, 16 / 9, 18.88689, FovType.Y, 25, 9.75);
  };

  return {
    normal: pointOfView(aabbInstance),
    up: aabbFacePointOfView(aabbInstance, Face.up, 16 / 9),
  }
};

/**
 * Get snapshot from the 3D scene
 * @param {Object} body - The request body
 * @returns {Promise<Object>} The snapshot response
 */
export const getSnapshot = (body) => {
  const url = `${process.env.REACT_APP_LUMISCAPHE_PUBLIC_ORIGIN}/Snapshot`;
  return post(url, body, {}, {}, true);
};

/**
 * Get AABB (Axis-Aligned Bounding Box) data
 * @param {Object} body - The request body
 * @returns {Promise<Object>} The AABB response
 */
export const getAabb = (body) => {
  const url = `${process.env.REACT_APP_LUMISCAPHE_PUBLIC_ORIGIN}/Aabb`;
  return post(url, body, {}, {}, true);
};

/**
 * Get Pick data from the 3D scene
 * @param {Object} body - The request body
 * @returns {Promise<Object>} The Pick response
 */
export const getPick = (body) => {
  const url = `${process.env.REACT_APP_LUMISCAPHE_PUBLIC_ORIGIN}/Pick`;
  return post(url, body, {}, {}, true);
};

/**
 * Get full image URL by concatenating base URL with image path
 * @param {string} path - The image path to concatenate
 * @returns {string} The complete image URL
 */
export const getImageUrl = (snapshotLink) => {
  if (!snapshotLink) return '';
  return `${process.env.REACT_APP_LUMISCAPHE_PUBLIC_ORIGIN}${snapshotLink}`;
};
