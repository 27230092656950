import * as types from './actionTypes';
import * as api from '../services/api.service';
import * as _ from 'lodash';
import {imgs} from '../services/images.service';
import {getVeneerDefaultData, inValidVisual} from '../services/utils.service';
import {isQuote} from '../services/restrictedConfiguration';
import {updateConfigWithQuote} from './quoteActions';
import { buildAndTransformFrameScenes, buildAndTransformVeneerScenes } from '../services/lumiscaphe.service';
import {displayStandardConfigImage} from '../services/image.fct.service';
import {
  warnOnTooManyItems,
  checkVeneerAndFrameDimensions,
  displayLayoutBaseHeightWarning,
} from '../services/configurationWarningChecks'
import { frameDimensions, defaultFrame } from '../services/frame.service';
import {getStore} from '../store';

let currentUpdatePromise = null;
let pendingUpdateConfiguration = null;
let updateIsoView = false;

export function updateHeadstone(headstone) {
  updateIsoView = true;

  return updateConfig({
    type: types.CONFIG_UPDATE_HEADSTONE,
    payload: headstone
  });
}

export function updateLayout(layout) {
  updateIsoView = true;

  return updateConfig({
    type: types.CONFIG_UPDATE_LAYOUT,
    payload: layout
  });
}

export function updateBaseHeight(baseHeight) {
  updateIsoView = true;
  return updateConfig({
    type: types.CONFIG_UPDATE_BASE_HEIGHT,
    payload: baseHeight
  });
}

export function updateFrame(frame) {
  updateIsoView = true;

  // asynchrone
  checkVeneerAndFrameDimensions();

  return updateConfig({
    type: types.CONFIG_UPDATE_FRAME,
    payload: frame
  });
}

export function removeFrame() {
  updateIsoView = true;

  // asynchrone
  checkVeneerAndFrameDimensions();

  return updateConfig({
    type: types.CONFIG_REMOVE_FRAME,
  });
}

export function updateVeneer(veneer,display) {
  if (display){
      updateIsoView = true;
      // displayVeneerWarning();
  }

  // asynchrone
  checkVeneerAndFrameDimensions();

  return updateConfig({
    type: types.CONFIG_UPDATE_VENEER,
    payload: veneer
  });
}

export function saveDefaultVeneer(defaultVeneerObj, clientId) {
  return async (dispatch) => {
    try {
      updateIsoView = false;
      const data = JSON.stringify(defaultVeneerObj);
      const stringToBase64 = btoa(data);
      const resp = await api.put(`/api/organizations/${clientId}/dafaultveneer/${stringToBase64}`);

      dispatch({
        type: types.CONFIG_UPDATE_DEFAULT_VENEER,
        payload: resp?.defaultVeneerOraganization
      });

      return resp?.defaultVeneerOraganization;
    } catch (error) {
      console.error('Error saving default veneer:', error);
      throw error;
    }
  };
}

export function removeVeneer() {
  updateIsoView = true;
  // asynchrone
  checkVeneerAndFrameDimensions();

  return updateConfig({
    type: types.CONFIG_REMOVE_VENEER,
  });
}
export function displayVeneer(display) {
  updateIsoView = true;

  return updateConfig({
    type: types.CONFIG_UPDATE_DISPLAY_VENEER,
    payload: display
  });
}

export function updatePath(key, value) {
  return updateConfig({
    type: types.CONFIG_UPDATE_AT_PATH,
    payload: {
      key,
      value
    }
  });
}

export function updateFamilyInfo() {
  return  (dispatch, getState) => { 
    dispatch({
      type: types.CONFIGURATION_FAMILY_INFO_UPDATE_SUCCESS,
    });
  }
}

export function updateGraniteGlobal(granite, graniteSecondary) {
  updateIsoView = true;

  return updateConfig({
    type: types.CONFIG_UPDATE_GRANITE_GLOBAL,
    payload: {gm: granite, gs: graniteSecondary}
  });
}

export function updateGraniteMonument(granite) {
  updateIsoView = true;

  return updateConfig({
    type: types.CONFIG_UPDATE_GRANITE_MONUMENT,
    payload: granite
  });
}

export function updateGraniteFrame(granite) {
  updateIsoView = true;

  return updateConfig({
    type: types.CONFIG_UPDATE_GRANITE_FRAME,
    payload: granite
  });
}

export function updateGraniteVeneer(granite) {
  updateIsoView = true;
  
  return updateConfig({
    type: types.CONFIG_UPDATE_GRANITE_VENEER,
    payload: granite
  });
}

export function updatePrice(key, value) {
  return updateConfig({
    type: types.CONFIG_UPDATE_ITEM,
    payload: {
      key: key + '.price.custom',
      value
    }
  });
}
export function updateQty(key, value, otherKeys = []) {
  return updateConfig({
    type: types.CONFIG_UPDATE_ITEM,
    payload: {
      key: key + '.quantity',
      value,
      otherKeys
    }
  });
}
export function updateProductId(key, value) {
  return updateConfig({
    type: types.CONFIG_UPDATE_ITEM,
    payload: {
      key: key + '.productId',
      value
    }
  });
}
export function updateOrderInfos(key, value) {
  return updateConfig({
    type: types.CONFIG_UPDATE_ITEM,
    payload: {
      key: 'order.' + key,
      value
    }
  });
}

export function addPattern(face, pattern) {
  updateIsoView = true;

  return updateConfig({
    type: types.CONFIG_UPDATE_PATTERN_ADD,
    payload: {
      face,
      pattern,
    }
  });
}

export function removePatterns(patterns) {
  updateIsoView = true;

  return updateConfig({
    type: types.CONFIG_UPDATE_PATTERN_REMOVE,
    payload: patterns
  });
}

export function removePatternsByPiece(piece) {
  updateIsoView = true;

  return updateConfig({
    type: types.CONFIG_UPDATE_PATTERN_REMOVE_BY_PIECE,
    payload: piece
  });
}

export function removeAllPatterns() {
  updateIsoView = true;

  return updateConfig({type: types.CONFIG_UPDATE_PATTERN_REMOVE_ALL});
};

export function updatePatterns(patterns) {
  updateIsoView = true;

  return updateFabricElements({patterns});
}

export function updatePatternOfPattern(patternId, pattern) {
  updateIsoView = true;

  return updateConfig({
    type: types.CONFIG_UPDATE_PATTERN_UPDATE_PATTERN,
    payload: {
      patternId,
      pattern,
    }
  });
}

export function duplicatePattern(patternId) {
  updateIsoView = true;

  return updateConfig({
    type: types.CONFIG_UPDATE_PATTERN_DUPLICATE,
    payload: patternId
  });
}

export function addEngraving(user,face, reservation,text) {
  updateIsoView = true;
  return updateConfig({
    type: types.CONFIG_UPDATE_ENGRAVING_ADD,
    payload: {
      face,
      reservation,
      text,
      user
    }
  });
}

export function removeEngravings(engravings) {
  updateIsoView = true;

  return updateConfig({
    type: types.CONFIG_UPDATE_ENGRAVING_REMOVE,
    payload: engravings
  });
}

export function removeEngravingsByPice(piece) {
  updateIsoView = true;
  return updateConfig({
    type: types.CONFIG_UPDATE_ENGRAVING_REMOVE_BY_PIECE,
    payload: piece
  });
}

export function removeReservations(reservations) {
  return updateConfig({
    type: types.CONFIG_UPDATE_RESERVATION_REMOVE,
    payload: reservations
  });
}

export function removeAllEngravings () {
  updateIsoView = true;
  return updateConfig({type: types.CONFIG_UPDATE_ENGRAVING_REMOVE_ALL});
};

export function updateEngravings(engravings) {
  updateIsoView = true;
  return updateFabricElements({engravings});
}

export function duplicateEngraving(engravingId, x, y) {
  updateIsoView = true;

  return updateConfig({
    type: types.CONFIG_UPDATE_ENGRAVING_DUPLICATE,
    payload: {
      engravingId,
      x,
      y,
    }
  });
};

/**
 * Fonctionne un peu comme la duplication sauf que l'on fournit le texte et les positions
 * @param engravingId Id de la gravure sur laquelle on a fait un saut de ligne
 * @param text Le texte de la nouvelle ligne
 * @param position La position de la nouvelle ligne
 * @param lines La vraie position de la ligne
 */
export function addNewLineForEngraving(engravingId, text, position, lines, updateState = true) {
  updateIsoView = true;

  return updateConfig({
    type: types.CONFIG_UPDATE_ENGRAVING_NEW_LINE,
    payload: {
      engravingId,
      text,
      position,
      lines,
    }
  }, true, updateState);
};

export function changeFlowerDisplay (hideFlowers){
  updateIsoView = true;
  return updateConfig({
    type: types.CONFIG_UPDATE_FLOWER_DISPLAY,
    payload: hideFlowers
  });

}

export function addAccessory(accessory, granite) {
  updateIsoView = true;

  return updateConfig({
    type: types.CONFIG_UPDATE_ACCESSORY_ADD,
    payload: {
      accessory,
      granite,
    }
  });
};

export function removeAccessories(accessories) {
  updateIsoView = true;

  return updateConfig({
    type: types.CONFIG_UPDATE_ACCESSORY_REMOVE,
    payload: accessories
  });
};

export function removeAllAccessories() {
  updateIsoView = true;

  return updateConfig({type: types.CONFIG_UPDATE_ACCESSORY_REMOVE_ALL});
};

export function updateAccessories(accessories) {
  updateIsoView = true;
  return updateFabricElements({accessories});
}

export function duplicateAccessory(accessoryId, x, y) {
  updateIsoView = true;

  return updateConfig({
    type: types.CONFIG_UPDATE_ACCESSORY_DUPLICATE,
    payload: {
      accessoryId,
      x,
      y,
    }
  });
};

export function updateFabricElements(elements, updateState = true) {
  updateIsoView = true;

  return updateConfig({
    type: types.CONFIG_UPDATE_FABRIC_ELEMENTS,
    payload: elements
  }, true, updateState);
};

export function updateAllConfig(config) {
  return {
    type: types.CONFIG_UPDATE_ALL_CONFIG,
    payload: config
  };
};

export function updateAdditionalLine(index, field, value) {
  return updateConfig({
    type: types.CONFIG_UPDATE_ITEM,
    payload: {
      key: 'additional.lines.' + index + '.' + field,
      value
    }
  });
}

export function removeItem(key, index) {
  updateIsoView = true;

  return updateConfig({
    type: types.CONFIG_REMOVE_ITEM,
    payload: {
      key,
      index
    }
  });
}

export function pushItem (key, item) {
  return updateConfig({
    type: types.CONFIG_PUSH_ITEM,
    payload: {
      key,
      item
    }
  });
};

export function setNewfamilyInfosEmail (email) {
  
    return (dispatch, getState) => {
      const current = getState().configurator.current;
      if(current){
        current.configuration.newfamilyInfos = {email};
        return doUpdate(dispatch, current.configuration);
      }
      return false
    };

};

function updateConfig(action,history=true, updateState = true) {
  return (dispatch, getState) => {
    if (history && updateIsoView) {dispatch({type:types.CONFIG_ADD_HISTORY})}
    const prevCurrent = getState().configurator.current;

    if (!isQuote(prevCurrent.configuration) && updateIsoView && !getState().isoView.loading) {

      dispatch({type: types.CONFIG_UPDATE_ISO_VIEW_LOADING});
    }


    dispatch(action);

    // Sauvegarde automatique
    const configuration = getState().configurator.current.configuration;

    const options =  getState().configurator.current.options;
    const user = getState().user;
    
    const isFamilyConfig = window?.location?.href?.includes("famille");
    if (updateState && configuration.monument.kind !== "ACC" && !isFamilyConfig) {
      const veneerDefault = getVeneerDefaultData(getState().configurator.current.options.defaultVeneer);
      updateFrameAndVeneerScenes(configuration, options.layouts, false, veneerDefault);
    }

    if (isQuote(configuration) && updateState)
    {
      const current = getState().configurator.current;
      callUpdateQuote(dispatch,configuration,action,user,options,prevCurrent,current);
    }else{
      if (currentUpdatePromise) {
        pendingUpdateConfiguration = configuration;
      } else if (updateState) {
        if (history && updateIsoView){
          callUpdate(dispatch, configuration, history, action);
        }else {
          doUpdate(dispatch, configuration, history, action);
        }
      }
      // asynchrone
      warnOnTooManyItems() 
    };
  }
}

export function updateFrameAndVeneerScenes(configuration, layouts, forPreview = false, veneerDefault = null) {
  let framepoints = {
    semelleTranslationX: 0,
    semelleTranslationY: 0,
    semelleTranslationZ: 0
  }
  let sceneResult;
  const selectedLayout = _.find(layouts, {reference: configuration.monument.layout});

  if (configuration.frame?.width) {
    configuration.frame.dimensions = frameDimensions(configuration.frame, selectedLayout.size);
    sceneResult = buildAndTransformFrameScenes(configuration, selectedLayout);
    const frameScenes = sceneResult.frameScenes;
    configuration.frameScenes = frameScenes.length ? JSON.stringify(frameScenes) : "";
    if (forPreview) {
      return frameScenes.map(scene => {
        const { materials, ...rest } = scene;
        return rest;
      });
    }
    // console.log("frameScenes", frameScenes)
  }
  else configuration.frameScenes = "";
  if (sceneResult) {
    framepoints = sceneResult?.transPoints;
  }
  if (configuration?.frame) {
    configuration.frame.coords = {
      x: framepoints.semelleTranslationX,
      y: framepoints.semelleTranslationY,
      z: framepoints.semelleTranslationZ
    }
  }

  if (configuration.veneer?.layout) {
    const veneerScenes = buildAndTransformVeneerScenes(configuration, framepoints, configuration.frame, selectedLayout, veneerDefault);
    configuration.veneerScenes = veneerScenes.length ? JSON.stringify(veneerScenes) : "";
    if (forPreview) {
      return veneerScenes.map(scene => {
        const { materials, ...rest } = scene;
        return rest;
      });
    }
    // console.log("veneerScenes", veneerScenes)
  } else configuration.veneerScenes = "";
}

const callUpdateQuote = _.debounce((dispatch,configuration,action,user,options,prevCurrent,current) => {
  updateConfigWithQuote(dispatch,configuration,action,user,options,prevCurrent,current);
}, 500);

const callUpdate = _.debounce((dispatch, configuration, history=true, action=false) => {
  doUpdate(dispatch, configuration, history, action);
}, 500);

const doUpdate = async (dispatch, configuration,history,action) => {
  try {
    currentUpdatePromise = await api.put(`/api/configuration/${configuration.reference}`, configuration)
    .then(async configAndOptions => {
      if(configAndOptions.err) {
        doneUpdating(dispatch, () => dispatch({
          type: types.CONFIG_UPDATE_FAILURE,
          payload: "erreur ..."
        }));
        return;
      }
      if(action && (action.type === "CONFIG_UPDATE_LAYOUT" || action.type === "CONFIG_UPDATE_BASE_HEIGHT")) {
        const faces = configAndOptions.options.faces.map(face=> face.piece.reference)
        let patterns = [], engravings = []
        configAndOptions.configuration.patterns.forEach(pattern=>{
            const faceDefined = pattern.face ? faces.find(face=> face.includes("STELE") && face.includes(`-${pattern.face.piece.split("-")[1]}`)) : null
            if (faceDefined) {
              pattern.face.piece = faceDefined
            }
            if (!pattern.face || faceDefined || (pattern.face && faces.find(face=> face.includes(pattern.face.piece)))) {
              patterns.push(pattern)
            }
            
          
        })
        configAndOptions.configuration.engravings.forEach(engraving=>{

            const faceDefined = engraving.face ? faces.find(face=> face.includes("STELE") && face.includes(`-${engraving.face.piece.split("-")[1]}`)) : null
            if (faceDefined) {
              engraving.face.piece = faceDefined
            }
            if (!engraving.face || faceDefined || (engraving.face && faces.find(face=> face.includes(engraving.face.piece)))) {
              engravings.push(engraving)
            }
        })
        configAndOptions.configuration.engravings = engravings
        configAndOptions.configuration.patterns = patterns
        await api.put(`/api/configuration/${configAndOptions.configuration.reference}`, configAndOptions.configuration)
        .then(configAndOpt=> {
          configAndOptions = configAndOpt
        })
      }
      doneUpdating(dispatch, async () => {
        
        if(action && action.type === "CONFIG_UPDATE_LAYOUT" && configAndOptions.configuration.monument.category === "SEM"){
          const parameters = getStore().getState().configurator.parameters
          const newFrame = await defaultFrame(configAndOptions, parameters)
          configAndOptions.configuration.frame = newFrame
          const veneerDefault = getVeneerDefaultData(configAndOptions.options.defaultVeneer);
          updateFrameAndVeneerScenes(configAndOptions.configuration, configAndOptions.options.layouts, veneerDefault);
          api.put(`/api/configuration/${configAndOptions.configuration.reference}`, configAndOptions.configuration)
          .then(configAndOpt=> {
            doneUpdating(dispatch, () => {
              checkVeneerAndFrameDimensions();
              dispatch({
                type: types.CONFIG_UPDATE_SUCCESS,
                payload: configAndOpt
              });
              if (updateIsoView) {
                updateIsoView = false;
                //TODO image async
                const isoView = displayStandardConfigImage(configAndOptions.configuration)
                // api.get(imgs.configuration.iso.large(configAndOptions.configuration,configAndOptions))
                // .then(isoView => {
                  dispatch({
                    type: types.CONFIG_UPDATE_ISO_VIEW,
                    payload: isoView
                  });
                // });
              }
            })
          })
        } else {
          const showMessage = inValidVisual(configAndOptions.configuration.monument)
          displayLayoutBaseHeightWarning(showMessage);
          dispatch({
            type: types.CONFIG_UPDATE_SUCCESS,
            payload: configAndOptions
          });
          if (updateIsoView) {
            updateIsoView = false;
            //TODO image async
            const isoView = displayStandardConfigImage(configAndOptions.configuration)
            // api.get(imgs.configuration.iso.large(configAndOptions.configuration,configAndOptions))
            // .then(isoView => {
              dispatch({
                type: types.CONFIG_UPDATE_ISO_VIEW,
                payload: isoView
              });
            // });
          }
        }
      });
    })
    .catch(err => {
      doneUpdating(dispatch, () => dispatch({
        type: types.CONFIG_UPDATE_FAILURE,
        payload: err.message || err
      }));
      if(err.response.status === 403) {  // Si la config est deja passer en commande ou en devis
        // disable img loader 
        dispatch({ type: types.SET_OFF_ISO_VIEW_LOADING });
        // show warning msg 
        dispatch({ type: types.UI_REMOVE_MESSAGES, payload : { id: 'isConfigRequested' } });
        dispatch({
          type: types.UI_ADD_MESSAGE,
          payload: { 
            id: 'isConfigRequested',
            className: 'warning',
            innerHTML:"Cette configuration a fait l'objet d'une demande auprès de GPG Granit, veuillez créer une variante pour la modifier."
          }
        })
        // disabled config btns
        dispatch({ type: types.CONFIGURATION_HAD_STATUS })
      }
    });
  } catch (e) {}
}

function doneUpdating(dispatch, updateStore) {
  if (pendingUpdateConfiguration) {
    const tmpConfig = pendingUpdateConfiguration;
    pendingUpdateConfiguration = null;
    callUpdate(dispatch, tmpConfig);
  } else {
    updateStore && updateStore();
    currentUpdatePromise = null;
  }
}

export function undo(){ 
  updateIsoView = true;
  return updateConfig({ type: types.CONFIG_UNDO},false);
};
export function redo(){   
  updateIsoView = true;
  return updateConfig({ type: types.CONFIG_REDO},false);
};

