import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { TranslationContext } from "../../../context/TranslationContext";
import * as _ from "lodash";
import * as updateConfigurationActions from "../../../actions/updateConfigurationActions";
import * as familySpaceActions from "../../../actions/familySpaceActions";
import cn from "classnames";
import {isQuote} from "../../../services/restrictedConfiguration";
import { isConfigRequested } from '../../../services/configurationWarningChecks';
import { AddressAutocomplete } from "../../../components/AddressAutocomplete";
/**
 * Panneau placé en haut de l'écran de finalisation qui permet d'éditer les réglages du projet
 */

class ProjectMetaInfoEditorComponent extends Component {
  static contextType = TranslationContext;
  // Affichage ou non du panneau d'édition des infos projet
  state = {
    showPanel: true,
    configuration: {},
    errorInvalidEmail: false,
    errorInvalidPhone: false,
    typesConcession: []
  };

  componentWillMount() {
    this.setState({
      configuration: _.clone(this.props.configuration),
      typesConcession: _.clone(this.props.typesConcession)
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.configuration !== nextProps.configuration) {
      this.setState(state => ({
        configuration: _.merge({}, nextProps.configuration, state.configuration)
      }));
    }
  }

  onChange = (path, value) => {
    return new Promise((resolve) => {
      this.setState(state => ({
          configuration: _.merge({}, state.configuration, _.set({}, path, value))
      }), resolve);
    });
  };

  onSave = async path => {
    this.onChange();
    const globalPath = path.indexOf('family') !== -1 ? 'family.contact' : 'project';
    const { configuration } = {...this.state};
    // On n'envoit pas l'événement s'il n'y a pas eu de modif
    if (_.get(this.props.configuration, globalPath) !== _.get(configuration, globalPath)) {
      const value = _.get(configuration, globalPath);
      let emailInvalid = null;
      let phoneInvalid = null;
      if (this.state.errorInvalidEmail) {
        emailInvalid = value.email;
        delete value.email;
      }
      if (this.state.errorInvalidPhone) {
        phoneInvalid = value.phone;
        delete value.phone;
      }
      this.props.actions.updatePath(globalPath, value);
      this.props.showNotif();
      // on conserve la valeur de l'email même si elle est incorrecte. mais ce n'est pas pris en compte dans la mise à jour
      // emailInvalid && (this.state.configuration.family.contact.email = emailInvalid);
      this.setState((prevState) => ({
        configuration: {
          ...prevState.configuration,
          family: {
            ...prevState.configuration.family,
            contact: {
              ...prevState.configuration.family.contact,
              email: emailInvalid || prevState.configuration?.family?.contact?.email,
              phone: phoneInvalid || prevState.configuration?.family?.contact?.phone,
            },
          },
        },
      }));

      // Si on sauvegarde un changement de nom et que la référence projet n'est pas renseigné,
      // on la renseigne avec le nom
      if (
        path.indexOf('family') !== -1 &&
        !_.get(this.props.configuration, "project.customer")
      ) {
        this.setState(state => ({
          configuration: _.merge(
            {},
            state.configuration,
            _.set({}, globalPath, value)
          )
        }));
        this.props.actions.updatePath(globalPath, value);
      }
    }
  };

  toggle = () => {
    this.setState({ showPanel: !this.state.showPanel });
  };

  validateEmail = () => {
    let {contact} = this.state.configuration.family
    let email = (contact && contact.hasOwnProperty("email")) ? contact.email : null
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+){1,2}$/;
    if (email && email.match(emailRegex)) {
      this.setState({ errorInvalidEmail: false });
      return true;
    } else if (email && !email.match(emailRegex)) {
      this.setState({ errorInvalidEmail: true });
      return false;
    } else {
      this.setState({ errorInvalidEmail: false });
      return true;
    }
  }

  validatePhone = () => {
    let {contact} = this.state.configuration.family
    let phoneNumber = (contact && contact.hasOwnProperty("phone")) ? contact.phone : null
    const phoneRegex = /^(0[1-9])\d{8}$/;
    const cleanedPhoneNumber = phoneNumber.replace(/\s/g, '');
    if (cleanedPhoneNumber && cleanedPhoneNumber.match(phoneRegex)) {
      this.setState({ errorInvalidPhone: false });
      return true;
    } else if (cleanedPhoneNumber && !cleanedPhoneNumber.match(phoneRegex)) {
      this.setState({ errorInvalidPhone: true });
      return false;
    } else {
      this.setState({ errorInvalidPhone: false });
      return true;
    }
  }

  changeSelectedTown = async (address) => {
    const { value: { properties: { city, postcode, name } } } = address;

    const paths = [
        ["family.contact.street", name],
        ["family.contact.postal", postcode],
        ["family.contact.city", city]
    ];

    await Promise.all(paths.map(([path, value]) => this.onChange(path, value)));
    await Promise.all(paths.map(([path]) => this.onSave(path)));
  };

  render() {
    const t = this.context;
    const { configuration, typesConcession } = this.state;
    return (
      <div
        className={
          "ProjectMetaData custom-fin-modal " +
          (this.state.showPanel ? "showPanel" : "collapsed")
        }
        style={{
          height: "100%",
          overflow: "auto"
        }}
      >
        <div className="Header">
          <div className="HeaderContainer">
            <h1 className="Headline">{t("config3d_finalize_project-info-modal_title") || "Saisie des informations famille"}</h1>
          </div>
        </div>

        <div className="content">
          <div className="row">
            <div className="col-sm">
              <h5>
                {t("config3d_finalize_project-info-modal_subtitle") || "Vous pouvez envoyer aux membres de la famille l'accès aux documents du projet ci-dessous"}
              </h5>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-sm-12 col-md-6">

              <div className="form-group">
                <label className="required">{t("config3d_finalize_project-info_family-project") || "Projet"}</label>
                <div className={cn({ error: !_.get(configuration, "project.customer") }, "input-wrapper")}>
                  <MetaInfoInput
                    configuration={configuration}
                    configPath="project.customer"
                    placeholder={t("config3d_finalize_project-info_placeholder_cemetary-details")}
                    className="Customer custom-input-field"
                    onChange={this.onChange}
                    onSave={this.onSave}
                    required
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>{t("config3d_finalize_project-info_cemetary-details") || "Cimetière"}</label>
                    <div className="input-wrapper">
                      <MetaInfoInput
                        configuration={configuration}
                        configPath="project.location"
                        placeholder={t("config3d_finalize_project-info_cemetary-details_name") || "Nom du cimetière"}
                        className="Cemetery custom-input-field"
                        onChange={this.onChange}
                        onSave={this.onSave}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-6">
                  <div className="form-group">
                    <label>{t("config3d_finalize_project-info_cemetary-details_location") || "Emplacement"}</label>
                    <div className="input-wrapper">
                      <MetaInfoInput
                        configuration={configuration}
                        configPath="project.number"
                        placeholder={t("config3d_finalize_project-info_cemetary-details_location") || "Emplacement"}
                        className="CemeterySpot custom-input-field"
                        onChange={this.onChange}
                        onSave={this.onSave}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label>{t("config3d_finalize_project-info_plot-type") || "Type de concession"}</label>
                <div className="input-wrapper type-concession px-1">
                  <MetaInfoSelect
                    configuration={configuration}
                    options={typesConcession}
                    configPath="project.option"
                    className="FamilyStreet custom-input-field"
                    onChange={this.onChange}
                    onSave={this.onSave}
                  />
                </div>
              </div>

              <div className="form-group">
                <label>{t("config3d_finalize_project-info_notes") || "Notes"}</label>
                <div className="input-wrapper notes">
                  <MetaInfoTextArea
                    configuration={configuration}
                    placeholder={t("config3d_finalize_project-info_notes") || "Notes"}
                    configPath="project.notes"
                    className="custom-input-field"
                    onChange={this.onChange}
                    onSave={this.onSave}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-6 border-left-top">
              <div className="form-group">
                <label>{t("config3d_finalize_project-info_family-name") || "FAMILLE (nom qui apparaitra sur le devis)"}</label>
                <div className="family-contact-name-container input-wrapper">
                  <MetaInfoInput
                    configuration={configuration}
                    configPath="family.contact.name"
                    placeholder={t("config3d_finalize_project-info_family-name-placeholder")}
                    className="custom-input-field"
                    onChange={this.onChange}
                    onSave={this.onSave}
                  />
                </div>
              </div>


              <div className="form-group">
                <label>{t("config3d_finalize_family-info_address") || "Rue"}</label>
                <div className="family-street-container input-wrapper">
                  <AddressAutocomplete
                    configuration={configuration}
                    configPath={"family.contact.street"}
                    placeholder={t("config3d_finalize_family-info_placeholder_street") || "Adresse"}
                    changeSelectedTown={this.changeSelectedTown}
                    onChange={this.onChange}
                    onSave={this.onSave}
                    test={() => true} />
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>{t("config3d_finalize_family-info_placeholder_postal_code") || "Code Postal"}</label>
                    <div className="FamilyStreetContainer input-wrapper">
                      <MetaInfoInput
                        configuration={configuration}
                        placeholder={t("config3d_finalize_family-info_placeholder_postal_code") || "Code Postal"}
                        configPath={"family.contact.postal"}
                        className="FamilyPostalCode custom-input-field"
                        onChange={this.onChange}
                        onSave={this.onSave}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>{t("config3d_finalize_family-info_placeholder_city") || "Ville"}</label>
                    <div className="input-wrapper">
                      <MetaInfoInput
                        configuration={configuration}
                        placeholder={t("config3d_finalize_family-info_placeholder_city") || "Ville"}
                        configPath={"family.contact.city"}
                        className="FamilyCity custom-input-field"
                        onChange={this.onChange}
                        onSave={this.onSave}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>{t("config3d_finalize_family-info_phone") || "Téléphone"}</label>
                    <div className={this.state.errorInvalidPhone ? "input-wrapper error" : "input-wrapper"}>
                      <MetaInfoInput
                        configuration={configuration}
                        placeholder={t("config3d_finalize_family-info_placeholder_phone") || "Téléphone"}
                        configPath="family.contact.phone"
                        className="custom-input-field"
                        onChange={this.onChange}
                        onSave={this.onSave}
                        validatePhone={this.validatePhone}
                        inputName="phoneNumber"
                      />
                    </div>
                  </div>

                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>{t("config3d_finalize_family-info_email") || "Email"}</label>
                    <div className={this.state.errorInvalidEmail ? "input-wrapper error" : "input-wrapper"}>
                      <MetaInfoInput
                        type="email"
                        configuration={configuration}
                        placeholder={t("config3d_finalize_family-info_placeholder_email") || "Email"}
                        configPath="family.contact.email"
                        className="custom-input-field"
                        validateEmail={this.validateEmail}
                        onChange={this.onChange}
                        onSave={this.onSave}
                      />
                      {this.state.errorInvalidEmail && (
                        <div className="FieldHelp" id="email-msg-error">
                          <span className="FieldHelpArrow">&#9668;</span>
                          Veuillez saisir une adresse email valide.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="row" style={{
            justifyContent: "right"
          }}>
          <button
            onClick={this.props.onModalClose}
            style={{
              marginRight: "0.9375rem"
            }}
            className="button-primary button-medium text-cta float-right">
            <span className="save-icon icon-large text-cta-icon"></span>
            <span>{t("config3d_finalize_project-info-modal_closebutton") || "Fermer"}</span>
          </button>
          </div>
        </div>
      </div>
    );
  }
}

export const ProjectMetaInfoEditor = connect(
  state => ({
    configuration: state.configurator.current.configuration,
    typesConcession: state.user.typesConcession
  }),
  dispatch => ({
    actions: bindActionCreators(updateConfigurationActions, dispatch),
    familySpaceAction: bindActionCreators(familySpaceActions, dispatch),
  })
)(ProjectMetaInfoEditorComponent);

/**
 * Input texte relié à une clef de l'objet configuration. Permet de modifier la valeur de la clef en place
 */
export const MetaInfoInput = ({
  inputName = null,
  type = type || "text",
  configPath,
  configuration,
  onChange,
  onSave,
  validateEmail,
  validatePhone,
  ...attrs
}) => (<input
    type={type}
    value={_.get(configuration, configPath) || ""}
    onChange={ev => onChange(configPath, ev.target.value)}
    onBlur={async () => {
      const validateFunction = type === "email" ? validateEmail : inputName === "phoneNumber" ? validatePhone : null;
      if (validateFunction) {
        const isValid = await validateFunction();
        if (isValid) {
          onSave(configPath);
        }
      } else {
        onSave(configPath);
      }
    }}
    disabled={isConfigRequested(false)}
    {...attrs}
  />);

/**
 * TextArea texte relié à une clef de l'objet configuration. Permet de modifier la valeur de la clef en place
 */
const MetaInfoTextArea = ({
  configPath,
  configuration,
  onChange,
  onSave,
  ...attrs
}) => (
  <textarea
    rows="6"
    cols="50"
    value={_.get(configuration, configPath) || ""}
    onChange={ev => onChange(configPath, ev.target.value)}
    onBlur={() => onSave(configPath)}
    {...attrs}
    disabled={isConfigRequested()}
  />
);

const MetaInfoSelect = ({
  configPath,
  options,
  configuration,
  onChange,
  onSave,
}) => ( 
  <select
    className="FamilyTypeConcession"
    name="option"
    value={_.get(configuration, configPath) || ""}
    onChange={async (ev) => {
      await onChange(configPath, ev.target.value); onSave(configPath)
    }}
  >
    <option value=""></option>
    { options.map((option) => {
      return <option key={option} value={option}>{option}</option>
    })}
  </select>
);

const RadioButtonOption = ({
  configPath,
  configuration,
  onChange,
  onSave,
  ...attrs
}) => ( 
  <div 
  className ="option-project">
    <input 
      type="radio" 
      name="option" 
      value="caveau"
      checked={_.get(configuration, configPath) === "caveau"}
      onChange={async (ev) => {
        await onChange(configPath, ev.target.value); onSave(configPath)
      }}
      />
    <label for="xs">Caveau</label>

    <input 
      type="radio" 
      name="option" 
      value="pleine_terre"
      checked={_.get(configuration, configPath) === "pleine_terre"}
      onChange={async (ev) => {
        await onChange(configPath, ev.target.value); onSave(configPath)
      }}
      />
    <label for="s">Pleine terre</label>
  </div>
);