export let CATALOG_SEARCH_ACCESSORIES = "CATALOG_SEARCH_ACCESSORIES";
export const CATALOG_LOAD_START = 'CATALOG_LOAD_START';
export const CATALOG_LOAD_SUCCESS = 'CATALOG_LOAD_SUCCESS';
export const CATALOG_LOAD_FAILURE = 'CATALOG_LOAD_FAILURE';
export const CATALOG_LOAD_PRICE_START = 'CATALOG_LOAD_PRICE_START';
export const CATALOG_LOAD_PRICE_SUCCESS = 'CATALOG_LOAD_PRICE_SUCCESS';
export const CATALOG_LOAD_PRICE_FAILURE = 'CATALOG_LOAD_PRICE_FAILURE';

export const CATALOG_SEARCH = 'CATALOG_SEARCH';
export const CATALOG_MONU_SEARCH = 'CATALOG_MONU_SEARCH';
export const CATALOG_FILTER = 'CATALOG_FILTER';
export const CATALOG_FILTER_TYPE = 'CATALOG_FILTER_TYPE';
export const CATALOG_ORDER_SIMILAR = "CATALOG_ORDER_SIMILAR";
export const CATALOG_CHANGE_ORDER_MONUMENT = "CATALOG_CHANGE_ORDER_MONUMENT";
export const CATALOG_RESET_ALPHABETICAL_ORDER = "CATALOG_RESET_ALPHABETICAL_ORDER";

export const CATALOG_SELECT_MONUMENT = 'CATALOG_SELECT_MONUMENT';
export const CATALOG_UNSELECT_MONUMENT = 'CATALOG_UNSELECT_MONUMENT';

export const CONFIGURATION_INIT_START = 'CONFIGURATION_INIT_START';
export const CONFIGURATION_INIT_SUCCESS = 'CONFIGURATION_INIT_SUCCESS';
export const CONFIGURATION_FAMILY_INFO_UPDATE_SUCCESS = 'CONFIGURATION_FAMILY_INFO_UPDATE_SUCCESS';
export const CONFIGURATION_INIT_FAILURE = 'CONFIGURATION_INIT_FAILURE';
export const CONFIG3D_PARAMS = 'CONFIG3D_PARAMS';

export const CONFIGURATION_GET_START = 'CONFIGURATION_GET_START';
export const CONFIGURATION_GET_SUCCESS = 'CONFIGURATION_GET_SUCCESS';
export const CONFIGURATION_GET_FAILURE = 'CONFIGURATION_GET_FAILURE';
export const CONFIGURATION_REVERT_CONFIG = 'CONFIGURATION_REVERT_CONFIG';
export const CONFIGURATION_HAD_STATUS = 'CONFIGURATION_HAD_STATUS';
export const ADJUST_NAME_MONUMENT = 'ADJUST_NAME_MONUMENT';

export const CONFIGURATION_GET_ACCESSORIES_START = 'CONFIGURATION_GET_ACCESSORIES_START';
export const CONFIGURATION_GET_ACCESSORIES_SUCCESS = 'CONFIGURATION_GET_ACCESSORIES_SUCCESS';
export const CONFIGURATION_GET_SIMULATE_PAYMENT_SCHEDULE = 'CONFIGURATION_GET_SIMULATE_PAYMENT_SCHEDULE';
export const CONFIGURATION_GET_INTRO = 'CONFIGURATION_GET_INTRO';
export const CONFIGURATION_ADD_READ_ONBOARDING = 'CONFIGURATION_ADD_READ_ONBOARDING';

export const CONFIGURATION_STOP = 'CONFIGURATION_STOP';
export const CONFIGURATION_NEED_LAYOUT_FILTERING = 'CONFIGURATION_NEED_LAYOUT_FILTERING';

export const CONFIG_UPDATE_ISO_VIEW_LOADING = 'CONFIG_UPDATE_ISO_VIEW_LOADING';
export const CONFIG_UPDATE_ISO_VIEW = 'CONFIG_UPDATE_ISO_VIEW';
export const SET_OFF_ISO_VIEW_LOADING = 'SET_OFF_ISO_VIEW_LOADING';

export const CONFIG_ORDER_SUCESS = 'CONFIG_ORDER_SUCESS';
export const CONFIG_ORDER_FAILURE = 'CONFIG_START_ORDER';

export const CONFIG_UPDATE_SUCCESS = 'CONFIG_UPDATE_SUCCESS';
export const CONFIG_UPDATE_FAILURE = 'CONFIG_UPDATE_FAILURE';

export const CONFIG_UPDATE_HEADSTONE = 'CONFIG_UPDATE_HEADSTONE';
export const CONFIG_UPDATE_LAYOUT = 'CONFIG_UPDATE_LAYOUT';
export const CONFIG_UPDATE_BASE_HEIGHT = 'CONFIG_UPDATE_BASE_HEIGHT';
export const CONFIG_UPDATE_FRAME = 'CONFIG_UPDATE_FRAME';
export const CONFIG_REMOVE_FRAME = 'CONFIG_REMOVE_FRAME';
export const CONFIG_UPDATE_VENEER = 'CONFIG_UPDATE_VENEER';
export const CONFIG_UPDATE_DEFAULT_VENEER = 'CONFIG_UPDATE_DEFAULT_VENEER';
export const CONFIG_REMOVE_VENEER = 'CONFIG_REMOVE_VENEER';
export const CONFIG_UPDATE_DISPLAY_VENEER = 'CONFIG_UPDATE_DISPLAY_VENEER';

export const CONFIG_UPDATE_AT_PATH = 'CONFIG_UPDATE_AT_PATH';

export const CONFIG_REMOVE_ITEM = 'CONFIG_REMOVE_ITEM';
export const CONFIG_PUSH_ITEM = 'CONFIG_PUSH_ITEM';
export const CONFIG_UPDATE_ITEM = 'CONFIG_UPDATE_ITEM';

export const CONFIG_UPDATE_GRANITE_GLOBAL = 'CONFIG_UPDATE_GRANITE_GLOBAL';
export const CONFIG_UPDATE_GRANITE_MONUMENT = 'CONFIG_UPDATE_GRANITE_MONUMENT';
export const CONFIG_UPDATE_GRANITE_FRAME = 'CONFIG_UPDATE_GRANITE_FRAME';
export const CONFIG_UPDATE_GRANITE_VENEER = 'CONFIG_UPDATE_GRANITE_VENEER';

export const CONFIG_UPDATE_PATTERN_ADD = 'CONFIG_UPDATE_PATTERN_ADD';
export const CONFIG_UPDATE_PATTERN_REMOVE = 'CONFIG_UPDATE_PATTERN_REMOVE';
export const CONFIG_UPDATE_PATTERN_REMOVE_ALL = 'CONFIG_UPDATE_PATTERN_REMOVE_ALL';
export const CONFIG_UPDATE_PATTERN_REMOVE_BY_PIECE = 'CONFIG_UPDATE_PATTERN_REMOVE_BY_PIECE';
export const CONFIG_UPDATE_PATTERN_UPDATE_PATTERN = 'CONFIG_UPDATE_PATTERN_UPDATE_PATTERN';
export const CONFIG_UPDATE_PATTERN_DUPLICATE = 'CONFIG_UPDATE_PATTERN_DUPLICATE';

export const CONFIG_UPDATE_ENGRAVING_ADD = 'CONFIG_UPDATE_ENGRAVING_ADD';
export const CONFIG_UPDATE_ENGRAVING_REMOVE_ALL = 'CONFIG_UPDATE_ENGRAVING_REMOVE_ALL';
export const CONFIG_UPDATE_ENGRAVING_REMOVE = 'CONFIG_UPDATE_ENGRAVING_REMOVE';
export const CONFIG_UPDATE_ENGRAVING_REMOVE_BY_PIECE="CONFIG_UPDATE_ENGRAVING_REMOVE_BY_PIECE";
export const CONFIG_UPDATE_ENGRAVING_DUPLICATE = 'CONFIG_UPDATE_ENGRAVING_DUPLICATE';
export const CONFIG_UPDATE_ENGRAVING_NEW_LINE = 'CONFIG_UPDATE_ENGRAVING_NEW_LINE';

export const CONFIG_UPDATE_RESERVATION_REMOVE = 'CONFIG_UPDATE_RESERVATION_REMOVE';

export const CONFIG_UPDATE_ACCESSORY_ADD = 'CONFIG_UPDATE_ACCESSORY_ADD';
export const CONFIG_UPDATE_ACCESSORY_REMOVE = 'CONFIG_UPDATE_ACCESSORY_REMOVE';
export const CONFIG_UPDATE_ACCESSORY_REMOVE_ALL = 'CONFIG_UPDATE_ACCESSORY_REMOVE_ALL';
export const CONFIG_UPDATE_ACCESSORY_DUPLICATE = 'CONFIG_UPDATE_ACCESSORY_DUPLICATE';

export const CONFIG_UPDATE_FABRIC_ELEMENTS = 'CONFIG_UPDATE_FABRIC_ELEMENTS';
export const CONFIG_UPDATE_FLOWER_DISPLAY = 'CONFIG_UPDATE_FLOWER_DISPLAY'

export const CONFIG_UPDATE_ALL_CONFIG = 'CONFIG_UPDATE_ALL_CONFIG';
export const UPDATE_EMAIL_SENT = 'UPDATE_EMAIL_SENT'

export const CONFIG_LAST_SELECTION = 'CONFIG_LAST_SELECTION';

export const CONFIG_ADD_HISTORY = 'CONFIG_ADD_HISTORY';
export const CONFIG_UNDO = 'CONFIG_UNDO';
export const CONFIG_REDO = 'CONFIG_REDO';

export const USER_LOADED = 'USER_LOADED';
export const NO_USER = 'NO_USER';

export const USER_CONFIG = "USER_CONFIG";
export const USER_CONFIG_ERROR = "USER_CONFIG_ERROR";

export const UI_TOGGLE_SHOW_BUYING_PRICES = 'UI_TOGGLE_SHOW_BUYING_PRICES';
export const UI_TOGGLE_SHOW_SELLING_PRICES = 'UI_TOGGLE_SHOW_SELLING_PRICES';
export const UI_SET_CUSTOM_LINE_BEING_EDITED = 'UI_SET_CUSTOM_LINE_BEING_EDITED';

export const UI_TOGGLE_FQUOTE_B = 'UI_TOGGLE_FQUOTE_B';

export const UI_OPEN_PATTERN_PICKER = 'UI_OPEN_PATTERN_PICKER';
export const UI_CLOSE_PATTERN_PICKER = 'UI_CLOSE_PATTERN_PICKER';

export const UI_ADD_MESSAGE = 'UI_ADD_MESSAGE';
export const UI_MASK_MESSAGE = 'UI_MASK_MESSAGE';
export const UI_REMOVE_MESSAGES = 'UI_REMOVE_MESSAGES';

export const UI_EDIT_ACCESSORY_GRANITES_OF = 'UI_EDIT_ACCESSORY_GRANITES_OF';
export const UI_INIT_CHOICE_MADE = 'UI_INIT_CHOICE_MADE';

export const TUTORIAL_CLOSED = 'TUTORIAL_CLOSED';
export const TUTORIAL_OPENED = 'TUTORIAL_OPENED';

export const ADMIN_CONFIG_UPDATE_CONFIG_ELT = 'ADMIN_CONFIG_UPDATE_CONFIG_ELT';
export const ADMIN_CONFIG_GET_INIT_CONFIG = 'ADMIN_CONFIG_GET_INIT_CONFIG';

export const FS_LOGIN = 'FS_LOGIN';
export const FS_LOGOUT = 'FS_LOGOUT';
export const FS_USER = 'FS_USER';
export const FS_SPACE = 'FS_SPACE';
export const FS_EMAIL = 'FS_EMAIL';
export const FS_PROJECTS = 'FS_PROJECTS';
export const FS_UPDATE_SEARCHED_PROJECTS = 'FS_UPDATE_SEARCHED_PROJECTS';
export const FS_UPDATE_SEARCHED_TERMS = 'FS_UPDATE_SEARCHED_TERMS';
export const FS_PROJECTS_OFFSETS = 'FS_PROJECTS_OFFSETS';
export const FS_ARCHIVED_PROJECTS_OFFSETS = 'FS_ARCHIVED_PROJECTS_OFFSETS';
export const FS_ADD_COMMENT = 'FS_ADD_COMMENT';
export const FS_MEMBERS = 'FS_MEMBERS';
export const FS_MESSAGES = 'FS_MESSAGES';
export const FS_ADD_MESSAGE = 'FS_ADD_MESSAGE';
export const FS_SOCKET = 'FS_SOCKET';
export const FS_UPDATE_POT = "FS_UPDATE_POT";
export const FS_FILES = 'FS_FILES';
export const FS_CANDIDATES = 'FS_CANDIDATES';
export const FS_UPDATE_EMAIL_SENT = 'UPDATE_EMAIL_SENT';
export const FS_LOGIN_MODAL = 'FS_LOGIN_MODAL';
export const FS_TOKEN = 'FS_TOKEN';
export const FS_PROJECT_IMAGE = 'FS_PROJECT_IMAGE';
export const FS_SEARCHED_PROJECT_IMAGE = 'FS_SEARCHED_PROJECT_IMAGE';
export const FS_PROJECT_IMAGE_STATUS = 'FS_PROJECT_IMAGE_STATUS'; // is loading or is on error
export const FS_SEARCH_PROJECT_IMAGE_STATUS = 'FS_SEARCH_PROJECT_IMAGE_STATUS';
export const FS_ORGANISATION_INFOS = 'FS_ORGANISATION_INFOS';
export const FS_SELECTED_PROJECTS = 'FS_SELECTED_PROJECTS';
export const FS_DELETE_SELECTED_PROJECT = 'FS_DELETE_SELECTED_PROJECT';
export const FS_ADD_SELECTED_PROJECT = 'FS_ADD_SELECTED_PROJECT';
export const FS_ADD_DOCS = 'FS_ADD_DOCS';
export const FS_SEARCHED_PROJECTS_ADD_DOCS="FS_SEARCHED_PROJECTS_ADD_DOCS"
export const RESEARCH_STATE="RESEARCH_STATE"
export const WHITE_LABEL_STATUS = 'WHITE_LABEL_STATUS';


